import React, { useState } from 'react'

import { differenceInCalendarDays } from 'date-fns'
import _ from 'lodash'
import { ChevronDown } from 'lucide-react'

import { Event } from 'models/event'
import {
  DownloadWorkspaceHistory,
  Export,
} from 'models/fetchers/history-fetcher'
import type { Workspace } from 'models/workspace'
import Services from 'services'

import { Timezone } from 'utils/date-utils'
import { displayErrorMessage } from 'utils/toast'
import { download } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from 'components/ui/dropdown-menu'
import { Spinner } from 'components/ui/spinner'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'

import HistoryExportDialog from './export-dialog'

interface HistoryExportDropdownProps {
  workspace: Workspace
  isLoading: boolean
  events: Event[]
}

const HistoryExportDropdown: React.FC<HistoryExportDropdownProps> = ({
  workspace,
  isLoading,
  events,
}) => {
  const { trackEvent } = useAnalytics()
  const [isExporting, setIsExporting] = useState<boolean>(false)
  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [selectedTimezone, setSelectedTimezone] = useState<string>(userTz)
  const [isExportAllDialogOpen, setIsExportAllDialogOpen] =
    useState<boolean>(false)
  const [isExportFilteredDialogOpen, setIsExportFilteredDialogOpen] =
    useState<boolean>(false)

  const timezones = [userTz]
  if (userTz !== Timezone.UTC) {
    timezones.push(Timezone.UTC)
  }

  // If the workspace has no retention period, or the retention period is greater than 30 days, we
  // should only allow exporting the last 30 days (except the option to export filtered).
  // Otherwise, we should allow exporting all history (which is less than 30 days).
  const exportAllText =
    !workspace.retentionPeriod || workspace.retentionPeriod > 2_592_000 // 30 days
      ? 'Export last 30 days'
      : 'Export all'

  const onExport = async ({
    type,
    events,
  }: {
    type: 'all' | 'filtered'
    events: Event[]
  }): Promise<void> => {
    Services.HoneyComb.Record({
      metric: 'ui.history_workspace_export',
      workspace_slug: workspace.slug,
      export_type: type,
      event_count: events.length,
      event_time_range_in_days: _.isEmpty(events)
        ? 0
        : differenceInCalendarDays(
            new Date(events[0].created),
            new Date(events[events.length - 1].created)
          ) + 1,
      selected_timezone: selectedTimezone,
    })
    trackEvent('Workspace History Exported', {
      export_type: type,
      event_count: events.length,
      event_time_range_days: _.isEmpty(events)
        ? 0
        : differenceInCalendarDays(
            new Date(events[0].created),
            new Date(events[events.length - 1].created)
          ) + 1,
      selected_timezone: selectedTimezone,
    })

    setIsExporting(true)
    try {
      const url = await DownloadWorkspaceHistory({
        exportType: Export.Queries,
        workspaceSlug: workspace.slug,
        events: type === 'filtered' && events.length ? events : [],
        userTz: selectedTimezone,
      })
      download(url, false)
    } catch (error) {
      if (type === 'all') {
        displayErrorMessage(`Failed to ${_.lowerFirst(exportAllText)} history`)
      } else {
        displayErrorMessage(`Failed to export filtered history`)
      }
    } finally {
      setIsExporting(false)
    }
  }

  const isExportFilteredDisabled = isLoading || _.isEmpty(events)
  const exportFiltered = isExportFilteredDisabled ? (
    <Tooltip>
      <TooltipTrigger className="w-full">
        <DropdownMenuItem
          disabled={isExportFilteredDisabled}
          data-testid="history-export-filtered"
        >
          Export filtered
        </DropdownMenuItem>
      </TooltipTrigger>
      <TooltipContent>
        {isLoading
          ? 'Please wait while history is loading…'
          : 'No results to export, please update filters and try again'}
      </TooltipContent>
    </Tooltip>
  ) : (
    <DropdownMenuItem
      onClick={() => {
        setIsExportFilteredDialogOpen(true)
      }}
      data-testid="history-export-filtered"
    >
      Export filtered
    </DropdownMenuItem>
  )

  return (
    <>
      <HistoryExportDialog
        isDialogOpen={isExportAllDialogOpen}
        setIsDialogOpen={setIsExportAllDialogOpen}
        selectedTimezone={selectedTimezone}
        setSelectedTimezone={setSelectedTimezone}
        dialogTitle="Export history confirmation"
        dialogDescription="Select the timezone for the exported history timestamps."
        onExport={() => onExport({ type: 'all', events: [] })}
        timezones={timezones}
      />
      <HistoryExportDialog
        isDialogOpen={isExportFilteredDialogOpen}
        setIsDialogOpen={setIsExportFilteredDialogOpen}
        selectedTimezone={selectedTimezone}
        setSelectedTimezone={setSelectedTimezone}
        dialogTitle="Export history confirmation"
        dialogDescription="Select the timezone for the exported history timestamps."
        onExport={() => onExport({ type: 'filtered', events })}
        timezones={timezones}
      />
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={isExporting}
          className="data-[state=open]:bg"
        >
          <Button
            disabled={isExporting}
            variant="outline"
            data-testid="history-export"
          >
            {isExporting ? 'Exporting' : 'Export'}
            {isExporting ? (
              <Spinner className="ml-2 h-3 w-3" />
            ) : (
              <ChevronDown className="ml-2 h-4 w-4" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() => {
              setIsExportAllDialogOpen(true)
            }}
            data-testid="history-export-all"
          >
            {exportAllText}
          </DropdownMenuItem>
          {exportFiltered}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default HistoryExportDropdown
