/* tslint:disable */
/* eslint-disable */
/**
 * Vault API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VaultEventRunType = {
    NEW: 'NEW',
    EXTRA_FILES: 'EXTRA_FILES',
    EXTRA_COLUMNS: 'EXTRA_COLUMNS',
    RETRY: 'RETRY'
} as const;
export type VaultEventRunType = typeof VaultEventRunType[keyof typeof VaultEventRunType];


export function instanceOfVaultEventRunType(value: any): boolean {
    for (const key in VaultEventRunType) {
        if (Object.prototype.hasOwnProperty.call(VaultEventRunType, key)) {
            if (VaultEventRunType[key as keyof typeof VaultEventRunType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function VaultEventRunTypeFromJSON(json: any): VaultEventRunType {
    return VaultEventRunTypeFromJSONTyped(json, false);
}

export function VaultEventRunTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): VaultEventRunType {
    return json as VaultEventRunType;
}

export function VaultEventRunTypeToJSON(value?: VaultEventRunType | null): any {
    return value as any;
}

