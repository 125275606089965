import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'

import { useVaultStore } from 'components/vault/utils/vault-store'

import { useAuthUser } from './common/auth-context'

const GeneralStoreListener = () => {
  const userInfo = useAuthUser()
  const filesUploading = useGeneralStore(useShallow((s) => s.filesUploading))
  const pendingColumnIds = useVaultStore(useShallow((s) => s.pendingColumnIds))
  const pendingQueryFileIds = useVaultStore(
    useShallow((s) => s.pendingQueryFileIds)
  )

  React.useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (filesUploading.length > 0) {
        const message =
          'Files are still uploading. Are you sure you want to leave?'
        e.preventDefault()
        e.returnValue = message // For most browsers
        return message // For some older browsers
      }
      if (userInfo.IsVaultV2User && (pendingColumnIds || pendingQueryFileIds)) {
        e.preventDefault()
        e.returnValue = true
        return true
      }
    }

    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    // Depend on filesUploading, pendingColumnIds, pendingQueryFileIds to re-attach the listener if the state changes
  }, [
    filesUploading.length,
    userInfo.IsVaultV2User,
    pendingColumnIds,
    pendingQueryFileIds,
  ])

  return null
}

export default GeneralStoreListener
