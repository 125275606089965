import React from 'react'

import { Coins, FileText } from 'lucide-react'

import { UserInfo } from 'models/user-info'
import { Maybe } from 'types'

import { AssistantMode } from 'components/assistant-v2/components/assistant-mode-select'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'

export enum FileSource {
  FILES = 'files',
  VAULT = 'vault',
}

export enum DatabaseSource {
  MEMOS = 'memo',
  TAX = 'tax',
}

export type KnowledgeSource = FileSource | DatabaseSource

export type VaultKnowledgeSource = {
  type: FileSource.VAULT
  folderId?: string
  fileIds?: string[]
  query?: string
}

export type MemosKnowledgeSource = {
  type: DatabaseSource.MEMOS
  industries?: string[]
  jurisdictions?: string[]
}

export type TaxKnowledgeSource = {
  type: DatabaseSource.TAX
  corpusId?: string
  restricts?: string[]
}

// TODO: OpenAPI these...
export type KnowledgeSourceItem =
  | TaxKnowledgeSource
  | MemosKnowledgeSource
  | VaultKnowledgeSource

export const getKnowledgeSources = (
  userInfo: UserInfo,
  mode: AssistantMode
): Set<KnowledgeSource> => {
  const sources: Set<KnowledgeSource> = new Set()
  if (userInfo.isDocumentUser) {
    sources.add(FileSource.FILES)
  }
  if (mode !== AssistantMode.ASSIST) return sources
  // ------------------------------------------------------------
  // The following sources are only available in Assist mode
  // ------------------------------------------------------------
  if (userInfo.IsVaultKnowledgeSourceUser) {
    sources.add(FileSource.VAULT)
  }
  if (userInfo.IsAssistantTaxKnowledgeSourceUser) {
    sources.add(DatabaseSource.TAX)
  }
  // TODO(@nami): Uncomment this when memos KS is ready
  // if (userInfo.IsMemosUser) {
  //   sources.add(DatabaseSource.MEMOS)
  // }
  return sources
}

export const KnowledgeSourceConfig: Partial<
  Record<
    KnowledgeSource,
    {
      icon: React.ReactElement
      name: string // The string shown in source footer, e.g. 'Vault • 2 sources'
      label: string // The string shown in the dropzone to choose the source
      hideFooter?: boolean
    }
  >
> = {
  [FileSource.VAULT]: {
    icon: <FolderShieldIcon />,
    name: 'Vault',
    label: 'Choose from Vault',
    hideFooter: true,
  },
  [DatabaseSource.MEMOS]: {
    icon: <FileText />,
    name: 'Memos',
    label: 'Memos',
  },
  [DatabaseSource.TAX]: {
    icon: <Coins />,
    name: 'Tax AI Assistant',
    label: 'Tax AI Assistant',
  },
}

export const VAULT_SOURCE_SELECT_FILES_DESCRIPTION =
  'Select files from this project to query in Assistant'

export const getKnowledgeSourceHasFilters = (
  item: Maybe<KnowledgeSourceItem>
) => {
  return (
    !!item &&
    Object.values(DatabaseSource).includes(item.type as DatabaseSource)
  )
}

export const isVaultKnowledgeSource = (
  item: Maybe<KnowledgeSourceItem>
): item is VaultKnowledgeSource => {
  return !!item && item.type === FileSource.VAULT
}

export const isTaxKnowledgeSource = (
  item: Maybe<KnowledgeSourceItem>
): item is TaxKnowledgeSource => {
  return !!item && item.type === DatabaseSource.TAX
}
