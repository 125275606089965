import React from 'react'

import { CirclePlay } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { TagScope } from 'openapi/models/TagScope'
import { VaultFile } from 'openapi/models/VaultFile'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TaskType } from 'utils/task'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING } from 'components/vault/utils/use-document-classification-store'
import {
  GenerateNNResponseProps,
  DocumentClassificationAnalyticsData,
  REMOVE_PARAMS,
  projectsPath,
  queriesPath,
} from 'components/vault/utils/vault'
import {
  getSortedFilesBasedOnReviewQueryOrder,
  getQuestionsLimit,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

const VaultRunButton = ({
  isExampleProject,
  doesCurrentUserHaveEditPermission,
  generateNNResponse,
}: {
  isExampleProject: boolean | null
  doesCurrentUserHaveEditPermission: boolean
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()

  const currentProject = useVaultStore(
    useShallow((state) => state.currentProject)
  )
  const currentProjectMetadata = useVaultStore(
    useShallow((state) => state.currentProjectMetadata)
  )
  const folderIdToVaultFolder = useVaultStore(
    useShallow((state) => state.folderIdToVaultFolder)
  )
  const pendingColumnIds = useVaultStore(
    useShallow((state) => state.pendingColumnIds)
  )
  const pendingQueryFileIds = useVaultStore(
    useShallow((state) => state.pendingQueryFileIds)
  )
  const queryId = useVaultStore(useShallow((state) => state.queryId))
  const queryIdToState = useVaultStore(
    useShallow((state) => state.queryIdToState)
  )
  const queryIdToReviewState = useVaultStore(
    useShallow((state) => state.queryIdToReviewState)
  )
  const setPendingColumnIds = useVaultStore(
    useShallow((state) => state.setPendingColumnIds)
  )
  const setPendingQueryFileIds = useVaultStore(
    useShallow((state) => state.setPendingQueryFileIds)
  )
  const setReviewTask = useVaultStore(
    useShallow((state) => state.setReviewTask)
  )

  const reviewQuestionsPerQueryLimit = useVaultUsageStore(
    (s) => s.reviewQuestionsPerQueryLimit
  )

  const filesLimit = useVaultStore((state) => state.filesLimit)
  const maxQuestionCharacterLength = useVaultStore(
    (state) => state.maxQuestionCharacterLength
  )
  const minQuestionCharacterLength = useVaultStore(
    (state) => state.minQuestionCharacterLength
  )
  const isWorkflowRepsWarranties = useVaultStore(
    (state) => state.isWorkflowRepsWarranties
  )

  const isNewQuery = queryId === 'new'
  const isQueryLoading =
    queryIdToState[isNewQuery ? '' : queryId]?.isLoading || false
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission
  const hasToProcessFiles =
    pendingQueryFileIds && pendingQueryFileIds.length > 0

  const hasToProcessQuestions = pendingColumnIds && pendingColumnIds.length > 0
  const queryQuestions = queryIdToReviewState[queryId]?.questions ?? []
  const questionsToAnswer = queryQuestions.filter(
    (question) => pendingColumnIds?.includes(question.id)
  )
  const questionsLimit = getQuestionsLimit(
    queryIdToReviewState[queryId]?.questionsLimit,
    reviewQuestionsPerQueryLimit
  )

  const isRunButtonDisabled =
    isQueryLoading ||
    !canCurrentUserEditProject ||
    (hasToProcessFiles && queryQuestions.length === 0) ||
    (!hasToProcessFiles && !hasToProcessQuestions)

  const computeDocumentClassificationAnalyticsData = (
    fileIdsToProcess: string[]
  ): DocumentClassificationAnalyticsData[] => {
    const documentClassificationTags = fileIdsToProcess.flatMap((fileId) => {
      const file = currentProjectMetadata.descendantFiles?.find(
        (file) => file.id === fileId
      )
      return (
        file?.tags.filter(
          (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
        ) ?? []
      )
    })
    const documentClassificationData = documentClassificationTags.reduce(
      (acc, tag) => {
        const existingEntry = acc.find(
          (entry) => entry.typeDocumentClassification === tag.name
        )
        const tagKey =
          tag.name as keyof typeof DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING
        const groupingDocumentClassification =
          DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING[tagKey]
        if (existingEntry) {
          existingEntry.numDocuments++
        } else {
          acc.push({
            numDocuments: 1,
            typeDocumentClassification: tag.name,
            groupingDocumentClassification: groupingDocumentClassification,
          })
        }
        return acc
      },
      [] as DocumentClassificationAnalyticsData[]
    )
    return documentClassificationData
  }

  const handleRun = async () => {
    const projectId = currentProject?.id ?? ''
    const query = isNewQuery ? 'Untitled' : queryIdToState[queryId]?.query ?? ''
    const currentQueryFileIds = queryIdToState[queryId]?.fileIds ?? []
    const headerText = isNewQuery
      ? 'Processing'
      : queryIdToState[queryId]?.headerText ?? ''

    let readyToQueryFiles: VaultFile[] =
      currentProjectMetadata.descendantFiles?.filter((file) => {
        return currentQueryFileIds.includes(file.id)
      }) ?? []

    // If we have pendingQueryFileIds, we need to filter down
    // If we don't have new files, then we use all of the files in
    if (pendingQueryFileIds) {
      readyToQueryFiles =
        currentProjectMetadata.descendantFiles?.filter((file) => {
          return file.readyToQuery && pendingQueryFileIds.includes(file.id)
        }) ?? []
    }

    const sortedReadyToQueryFileIds = getSortedFilesBasedOnReviewQueryOrder(
      readyToQueryFiles,
      folderIdToVaultFolder
    ).map((file) => file.id)

    const documentClassificationAnalyticsData =
      computeDocumentClassificationAnalyticsData(sortedReadyToQueryFileIds)

    const existingQuestions =
      queryIdToReviewState[queryId]?.questions.filter(
        (question) => !pendingColumnIds?.includes(question.id)
      ) ?? []

    // TODO: handle case where we have files but no questions (all of the questions will not be in pendingColumnIds)

    const allQuestions = isNewQuery
      ? questionsToAnswer
      : [...existingQuestions, ...questionsToAnswer]
    await generateNNResponse({
      requestType: isNewQuery ? 'new' : 'extra_columns',
      queryId: isNewQuery ? undefined : queryId,
      query: query,
      folderId: projectId,
      fileIds: sortedReadyToQueryFileIds,
      documentClassificationAnalyticsData: documentClassificationAnalyticsData,
      questions: questionsToAnswer,
      questionsNotAnswered: [],
      existingQuestions: existingQuestions,
      questionsLimit: questionsLimit,
      filesLimit: filesLimit,
      maxQuestionCharacterLength: maxQuestionCharacterLength,
      minQuestionCharacterLength: minQuestionCharacterLength,
      dryRun: false,
      isWorkflowRepsWarranties: isWorkflowRepsWarranties,
      existingColumnOrder: [],
      clientMatterId: currentProjectMetadata.clientMatterId,
      onAuthCallback: (queryId) => {
        setPendingColumnIds(null)
        setPendingQueryFileIds(null)
        setReviewTask({
          isFromHistory: true,
          isLoading: true,
          queryId: queryId,
          query: query,
          headerText: headerText,
          taskType: TaskType.VAULT_REVIEW,
          vaultFolderId: projectId,
          startedAt: new Date(),
          numFiles: sortedReadyToQueryFileIds.length,
          fileIds: sortedReadyToQueryFileIds,
          followUpQueries: [],
          questions: allQuestions,
          columnHeaders: allQuestions.map((question) => ({
            id: question.id,
            text: question.header ?? '',
            columnDataType: question.columnDataType,
          })),
          questionsNotAnswered: [],
          dryRun: false,
          isWorkflowRepsWarranties: isWorkflowRepsWarranties,
          creatorUserEmail: userInfo.id,
        })
        if (isNewQuery) {
          const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}${queryId}`
          navigate(newPath, { replace: true }, REMOVE_PARAMS)
        }
      },
    })
  }

  if (!userInfo.IsVaultV2User) {
    return null
  }

  return (
    <Button
      className="flex items-center gap-1"
      onClick={handleRun}
      isLoading={isQueryLoading}
      disabled={isRunButtonDisabled}
    >
      <Icon icon={CirclePlay} />
      Run
    </Button>
  )
}

export default VaultRunButton
