import { DateRange } from 'react-day-picker'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { AnnotationById, Source, TaskType } from 'types/task'

import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'
import { HarveySocketCompletionStatus } from 'utils/use-harvey-socket-utils'

import { FeedbackResult } from 'components/common/feedback'

interface ActiveDocument {
  file: UploadedFile
  source: Source
}

interface State {
  query: string
  queryPreview: string
  openFilters: ResearchFilter[]
  selectedFilters: ResearchFilter[]
  response: string
  annotations: AnnotationById
  sources: Source[]
  isLoading: boolean
  headerText: string
  queryId: string | undefined
  caption: string
  feedback: FeedbackResult | null
  kind?: TaskType
  completionStatus: HarveySocketCompletionStatus | null
  activeDocument: ActiveDocument | null
  progress: number
  dateRange: DateRange | null
  researchArea: ResearchArea | null
}

interface Action {
  setQuery: (query: string) => void
  setQueryPreview: (query: string) => void
  setOpenFilters: (nodes: ResearchFilter[]) => void
  setSelectedFilters: (nodes: ResearchFilter[]) => void
  setFeedback: (feedback: FeedbackResult | null) => void
  setTask: HarveySocketSetter
  resetState: () => void
  setActiveDocument: (activeDocument: ActiveDocument | null) => void
  setDateRange: (dateRange: DateRange | null) => void
  setResearchArea: (researchArea: ResearchArea | null) => void
}

const initialState: State = {
  query: '',
  queryPreview: '',
  annotations: {},
  openFilters: [],
  selectedFilters: [],
  response: '',
  caption: '',
  sources: [],
  isLoading: false,
  headerText: '',
  queryId: undefined,
  feedback: null,
  completionStatus: null,
  activeDocument: null,
  progress: 0,
  dateRange: null,
  researchArea: null,
}

export const useResearchStore = create(
  devtools(
    immer<State & Action>((set) => ({
      // these doesn't actually use zustand so we need to update ref
      ...initialState,
      setQuery: (query: string) => {
        set((state) => ({
          ...state,
          query,
          queryPreview: '',
        }))
      },
      setQueryPreview: (queryPreview: string) => {
        set((state) => ({
          ...state,
          queryPreview,
        }))
      },
      setFeedback: (feedback: FeedbackResult | null) => {
        set((state) => ({
          ...state,
          feedback,
        }))
      },
      setOpenFilters: (openFilters: ResearchFilter[]) => {
        set((state) => ({
          ...state,
          openFilters,
        }))
      },
      setSelectedFilters: (selectedFilters: ResearchFilter[]) => {
        set((state) => ({
          ...state,
          selectedFilters,
        }))
      },
      setTask: (socketState: Partial<HarveySocketTask>) => {
        set((state) => ({
          ...state,
          ...socketState,
        }))
      },
      resetState: () => {
        set(() => ({
          ...initialState,
        }))
      },
      setActiveDocument: (activeDocument: ActiveDocument | null) => {
        set((state) => ({
          ...state,
          activeDocument,
        }))
      },
      setProgress: (progress: number) =>
        set((state) => ({
          ...state,
          progress,
        })),
      setDateRange: (dateRange: DateRange | null) => {
        set((state) => ({
          ...state,
          dateRange,
        }))
      },
      setResearchArea: (researchArea: ResearchArea | null) => {
        set((state) => ({
          ...state,
          researchArea,
        }))
      },
    }))
  )
)
