export const SIDEBAR_OPEN_WIDTH = 192
export const SIDEBAR_CLOSED_WIDTH = 72
export const SETTINGS_SIDEBAR_WIDTH = 192
export const SETTINGS_SIDEBAR_PADDING = 32

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}
