import React, { useEffect } from 'react'
import useState from 'react-usestateref'

import { Space, Table } from 'antd'
import _ from 'lodash'

import {
  type Workspace,
  type WorkspaceKind,
  FetchWorkspaces,
} from 'models/workspace'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { readableFormat } from 'utils/date-utils'
import { site } from 'utils/server-data'
import { getSiteColor, getSiteName } from 'utils/site'
import { cn, intervalToReadable } from 'utils/utils'

import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import SettingsLayout from 'components/settings/settings-layout'
import { Badge } from 'components/ui/badge'
import { Button as TailwindButton } from 'components/ui/button'
import SearchInput from 'components/ui/search-input'

const Workspaces = () => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [workspaceFilter, setWorkspaceFilter] = useState<string>('')
  const [filteredWorkspaces, setFilteredWorkspaces] = useState<Workspace[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (userInfo.IsInternalAdminReader) {
      void fetchWorkspaces()
    }
  }, [userInfo])

  const fetchWorkspaces = async (): Promise<void> => {
    setLoading(true)
    const data = await FetchWorkspaces()
    const sortedData = _.orderBy(
      data,
      [
        (workspace) => (workspace.deletedAt ? 1 : 0),
        (workspace) => workspace.id,
      ],
      ['asc', 'asc']
    )
    setWorkspaces(sortedData)
    setFilteredWorkspaces(sortedData)
    setWorkspaceFilter('')
    setLoading(false)
  }

  if (!userInfo.IsInternalAdminReader) return null

  const handleCreate = (): void => {
    navigate(`/settings/internal_admin/workspaces/new`)
  }

  const handleSearch = (value: string): void => {
    value = value.trim()
    setWorkspaceFilter(value)
    value = value.toLowerCase()
    if (value === '') {
      setFilteredWorkspaces(workspaces)
    } else {
      setFilteredWorkspaces(
        workspaces.filter((workspace) => workspaceMatch(workspace, value))
      )
    }
  }

  const workspaceMatch = (
    workspace: Workspace,
    textToMatch: string
  ): boolean => {
    const { friendlyName, slug, domains } = workspace
    const friendlyNameMatch = friendlyName?.toLowerCase().includes(textToMatch)
    const slugMatch = slug.toLowerCase().includes(textToMatch)
    const domainsMatch = domains.some((domain) =>
      domain.toLowerCase().includes(textToMatch)
    )
    const idMatch = workspace.id.toString().includes(textToMatch)
    const clientNameMatch = workspace.clientName
      .toLowerCase()
      .includes(textToMatch)
    return (
      friendlyNameMatch ||
      slugMatch ||
      domainsMatch ||
      idMatch ||
      clientNameMatch
    )
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
    },
    {
      title: 'Client name',
      dataIndex: 'clientName',
      key: 'clientName',
      width: '10%',
    },
    {
      title: 'Friendly name',
      dataIndex: 'friendlyName',
      key: 'friendlyName',
      width: '10%',
      render: (friendlyName: string, workspace: Workspace) => (
        <div className="flex flex-wrap justify-between">
          <span>{friendlyName}</span>
          {!_.isEmpty(workspace.settings.channelPartner) && (
            <Badge className="pt-1" variant="secondary">
              From{' '}
              {_.startCase(workspace.settings.channelPartner.toLowerCase())}
            </Badge>
          )}
        </div>
      ),
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      width: '10%',
    },
    {
      title: 'Site',
      key: 'site',
      width: '10%',
      render: (workspace: Workspace) => {
        const badgeColor =
          workspace.site === site ? '' : getSiteColor(workspace.site)
        return (
          <Badge
            variant="secondary"
            className={cn({ 'text-primary-inverse': !_.isEmpty(badgeColor) })}
            style={{ backgroundColor: badgeColor }}
          >
            {getSiteName(workspace.site)}
          </Badge>
        )
      },
    },
    {
      title: 'Kind',
      dataIndex: 'kind',
      key: 'kind',
      width: '10%',
      render: (kind: WorkspaceKind) => (
        <Badge variant="secondary">{kind.toString()}</Badge>
      ),
    },

    {
      title: 'Domains',
      dataIndex: 'domains',
      key: 'domains',
      width: '10%',
      render: (domains: string[]) => (
        <Space wrap>
          {domains.map((domain) => (
            <Badge variant="secondary" key={domain}>
              {domain}
            </Badge>
          ))}
        </Space>
      ),
    },
    {
      title: 'Retention period',
      dataIndex: 'retentionPeriod',
      key: 'retentionPeriod',
      width: '10%',
      render: (retentionPeriod?: number) =>
        !_.isNil(retentionPeriod)
          ? intervalToReadable(retentionPeriod, true)
          : 'Unlimited',
    },
    {
      title: 'Export feedback',
      dataIndex: 'exportFeedback',
      key: 'exportFeedback',
      width: '10%',
      render: (exportFeedback: boolean) =>
        !_.isNil(exportFeedback) && exportFeedback ? 'Enabled' : 'Disabled',
    },
    {
      title: 'Bucket',
      key: 'bucket',
      dataIndex: 'bucket',
      width: '10%',
    },
    {
      title: 'Deleted at',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      width: '10%',
      render: (timestamp?: string) =>
        _.isNil(timestamp) ? timestamp : readableFormat(new Date(timestamp)),
    },
  ]

  return (
    <>
      <AppHeader
        title="Settings"
        subtitle="Manage your settings"
        breadcrumbs={<div />}
        actions={
          <div className="flex grow justify-end">
            <div>
              {userInfo.IsInternalAdminWriter && (
                <TailwindButton onClick={handleCreate} className="mr-4">
                  Create workspace
                </TailwindButton>
              )}
            </div>
            <div>
              <SearchInput
                value={workspaceFilter}
                setValue={handleSearch}
                withIcon
              />
            </div>
          </div>
        }
        isInternalAdmin
      />
      <SettingsLayout>
        <Table
          dataSource={filteredWorkspaces}
          columns={columns}
          loading={loading}
          scroll={{ x: 1500 }}
          className="cursor-pointer"
          onRow={(workspace) => ({
            onClick: () => {
              navigate(`/settings/internal_admin/workspaces/${workspace.id}`)
            },
          })}
          bordered
          footer={() => (
            <div className="flex justify-end">
              <p>{filteredWorkspaces.length} workspaces</p>
            </div>
          )}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
          }}
        />
      </SettingsLayout>
    </>
  )
}

export default Workspaces
