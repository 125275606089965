import { isEmpty } from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'

import { isAzureBlobPdf } from 'utils/utils'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { isVaultKnowledgeSource } from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { EXPIRATION_URL_KEY } from 'components/vault/utils/vault'
import { FetchVaultFile } from 'components/vault/utils/vault-fetcher'
import { isUrlExpired } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface AssistantFileAction {
  getDocument: (
    eventId: string | null,
    fileId: string
  ) => Promise<UploadedFile | undefined>
}

interface AssistantFileState {
  documents: {
    [key: string]: UploadedFile
  }
}

const initialState: AssistantFileState = {
  documents: {},
}

export const useAssistantFileStore = create(
  devtools(
    immer<AssistantFileState & AssistantFileAction>((set, get) => ({
      ...initialState,
      getDocument: async (eventId: string | null, fileId: string) => {
        const knowledgeSource = useAssistantStore.getState().knowledgeSource
        if (isVaultKnowledgeSource(knowledgeSource)) {
          const vaultFile = useVaultStore.getState().fileIdToVaultFile[fileId]
          if (vaultFile) {
            const vaultFileUrl = vaultFile.url || vaultFile.docAsPdfUrl || ''
            if (
              !isEmpty(vaultFileUrl) &&
              !isUrlExpired(vaultFileUrl, EXPIRATION_URL_KEY)
            ) {
              return vaultFile
            }
          }
          const file = await FetchVaultFile(fileId)
          useVaultStore.getState().upsertVaultFiles([file])
          return file
        } else if (isAzureBlobPdf(fileId)) {
          const messages = useAssistantStore.getState().messages
          const sources = messages.flatMap((m) => m?.sources || [])
          const source = sources.find((s) => s.documentId?.startsWith(fileId))
          return {
            id: fileId,
            name: source?.documentName ?? 'Document',
            url: source?.documentUrl,
            path: '',
          }
        } else if (eventId) {
          const documents = get().documents
          if (fileId in documents) {
            return documents[fileId]
          }
          const response = await Services.Backend.Get<UploadedFile[]>(
            `assistant/files/${eventId}`
          )

          set((state) => {
            for (const document of response) {
              state.documents[document.id] = document
              // support old data type
              state.documents[document.name] = document
            }
          })
          return response.find((doc) => doc.name == fileId || doc.id === fileId)
        }
      },
    }))
  )
)
