import React, { useState } from 'react'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { DatabaseSource } from 'components/assistant-v2/utils/assistant-knowledge-sources'
import Explorer from 'components/research/explorer'

interface AssistantMemosKnowledgeSourceProps {
  onClose: () => void
}

export const AssistantMemosKnowledgeSource: React.FC<
  AssistantMemosKnowledgeSourceProps
> = () => {
  const { taxonomy, isLoading: isTaxonomyLoading } = useResearchTaxonomyQuery(
    ResearchArea.MEMOS
  )
  const [selectedFilters, setSelectedFilters] = useState<ResearchFilter[]>([])
  const setKnowledgeSource = useAssistantStore(
    (state) => state.setKnowledgeSource
  )

  const onSetSelectedFilters = (filters: ResearchFilter[]) => {
    setSelectedFilters(filters)

    if (filters.length > 0) {
      // TODO: Not sure if this is the correct way to get industries and jurisdictions
      const industries = Array.from(
        new Set(filters.map((f) => f.id.split('.')[0]))
      )
      const juristictions = filters.map((f) => f.id)

      if (!industries.length) return

      setKnowledgeSource({
        type: DatabaseSource.MEMOS,
        industries: industries,
        jurisdictions: juristictions,
      })
    }
  }

  return (
    <div className="w-full">
      <Explorer
        isOpen={true}
        taxonomy={taxonomy as ResearchFilter[]}
        selectedFilters={selectedFilters}
        setSelectedFilters={onSetSelectedFilters}
        isLoading={isTaxonomyLoading}
        researchArea={ResearchArea.TAX}
      />
    </div>
  )
}
