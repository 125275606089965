import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { VaultFolderShareStatus } from 'openapi/models/VaultFolderShareStatus'
import {
  PermissionLevel,
  PermissionsByUser,
  PermissionsByWorkspace,
} from 'types/sharing'

import { SafeRecord } from 'utils/safe-types'
import { getUserPermissionLevel } from 'utils/sharing-helpers'

export interface VaultSharingState {
  permissionsByProjectId: SafeRecord<string, VaultFolderShareStatus>
  currentUserPermissionByProjectId: SafeRecord<string, PermissionLevel>
  isFetchingFolderShareStatus: boolean
}

interface VaultSharingAction {
  setPermissionsForProjectId: ({
    projectId,
    userId,
    workspaceId,
    permissions,
  }: {
    projectId: string
    userId: string
    workspaceId: number
    permissions: VaultFolderShareStatus | null
  }) => void
  appendPermissionsForProjectId: ({
    projectId,
    userId,
    workspaceId,
    permissions,
  }: {
    projectId: string
    userId: string
    workspaceId: number
    permissions: VaultFolderShareStatus
  }) => void
  setIsFetchingFolderShareStatus: (isFetching: boolean) => void
}

export const useVaultSharingStore = create(
  devtools(
    immer<VaultSharingState & VaultSharingAction>((set) => ({
      permissionsByProjectId: {},
      currentUserPermissionByProjectId: {},
      isFetchingFolderShareStatus: false,
      setPermissionsForProjectId: ({
        projectId,
        userId,
        workspaceId,
        permissions,
      }: {
        projectId: string
        userId: string
        workspaceId: number
        permissions: VaultFolderShareStatus | null
      }) => {
        set((state) => {
          if (permissions) {
            state.permissionsByProjectId[projectId] = permissions
          } else {
            delete state.permissionsByProjectId[projectId]
          }
          updateCurrentUserPermissionLevelForProject({
            state,
            projectId,
            userId,
            workspaceId,
            permissions,
          })
        })
      },
      appendPermissionsForProjectId: ({
        projectId,
        userId,
        workspaceId,
        permissions,
      }: {
        projectId: string
        userId: string
        workspaceId: number
        permissions: VaultFolderShareStatus
      }) => {
        set((state) => {
          const existingPermissions = state.permissionsByProjectId[projectId]
          const existingPermissionsByWorkspace =
            existingPermissions?.permissionsByWorkspace || []
          const existingPermissionsByUser =
            existingPermissions?.permissionsByUser || []

          const updatedPermissionsByWorkspace =
            existingPermissionsByWorkspace.filter(
              (wp) =>
                !permissions.permissionsByWorkspace?.some(
                  (p) => p.workspaceId === wp.workspaceId
                )
            )
          const updatedPermissionsByUser = existingPermissionsByUser.filter(
            (up) =>
              !permissions.permissionsByUser?.some(
                (p) => p.userId === up.userId
              )
          )

          const newPermissions = {
            permissionsByWorkspace: [
              ...updatedPermissionsByWorkspace,
              ...(permissions.permissionsByWorkspace || []),
            ],
            permissionsByUser: [
              ...updatedPermissionsByUser,
              ...(permissions.permissionsByUser || []),
            ],
          }
          state.permissionsByProjectId[projectId] = newPermissions
          updateCurrentUserPermissionLevelForProject({
            state,
            projectId,
            userId,
            workspaceId,
            permissions,
          })
        })
      },
      setIsFetchingFolderShareStatus: (isFetching: boolean) => {
        set((state) => {
          state.isFetchingFolderShareStatus = isFetching
        })
      },
    }))
  )
)

function updateCurrentUserPermissionLevelForProject({
  state,
  projectId,
  userId,
  workspaceId,
  permissions,
}: {
  state: VaultSharingState
  projectId: string
  userId: string
  workspaceId: number
  permissions: VaultFolderShareStatus | null
}) {
  const permissionLevel = getUserPermissionLevel({
    userId: userId,
    workspaceId: workspaceId,
    permissionsByWorkspace: (permissions?.permissionsByWorkspace ??
      []) as PermissionsByWorkspace,
    permissionsByUser: (permissions?.permissionsByUser ??
      []) as PermissionsByUser,
  })
  if (permissionLevel) {
    state.currentUserPermissionByProjectId[projectId] = permissionLevel
  } else {
    delete state.currentUserPermissionByProjectId[projectId]
  }
}
