import React, { useEffect, useMemo } from 'react'
import ReactMarkdown, { defaultUrlTransform } from 'react-markdown'
import { Element } from 'react-markdown/lib'
import { useParams, useSearchParams } from 'react-router-dom'
import { useMount } from 'react-use'

import queryString from 'query-string'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'

import { useCaseLawQuery } from 'models/queries/use-case-law-query'
import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { EventKind } from 'openapi/models/EventKind'
import { ResearchArea } from 'openapi/models/ResearchArea'

import { extractMarkedTextSimple } from 'utils/source'

import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { MARKDOWN_SANITIZER_SCHEMA } from 'components/common/markdown/markdown'
import { RESEARCH_ARTIFACT_PATH } from 'components/research/research-helpers'
import { footnoteParam, queryIdParam } from 'components/research/util'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'components/ui/breadcrumbs/breadcrumb'
import Citation from 'components/ui/citation'
import Link from 'components/ui/link/link'
import { ScrollArea } from 'components/ui/scroll-area'
import Skeleton from 'components/ui/skeleton'

// URLs to other cases from the rawHtml which are formatted like
// '/research/usa_caselaw/{id}'
const URL_REGEX = /^\/research\/usa_caselaw\/(\d+)/

// Find all <mark id="source-{footnote" />
const MARK_ID_REGEX = /<mark id="source-(\d+)"/g

// Identifier prepended to footnote to identify highlighted elements
const SOURCE_PREFIX = 'source-'

// Name for metrics
const METRIC_PREFIX = `${EventKind.USA_CASELAW}_ARTIFACT`

const Artifact: React.FC = () => {
  const { id: artifactId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryId = searchParams.get(queryIdParam)

  const { setQueryViewed } = useQueryAnalytics(METRIC_PREFIX)
  useMount(() => {
    setQueryViewed()
  })

  const footnote = searchParams.get(footnoteParam)
  const scrollToFootnote = (footnoteId: string) => {
    const sourceId = `${SOURCE_PREFIX}${footnoteId}`
    const element = document.getElementById(sourceId)
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
  const setFootnote = (footnoteId: string) => {
    const nextParams = new URLSearchParams(searchParams)
    if (footnoteId !== footnote) {
      nextParams.set(footnoteParam, footnoteId)
      setSearchParams(nextParams, { replace: true })
    } else {
      scrollToFootnote(footnoteId)
    }
  }

  const { historyItem } = useHistoryItemQuery({ id: queryId })
  const historySources = historyItem?.sources?.filter(
    (source) => source.documentId === artifactId
  )
  const sources = useMemo(() => {
    if (!historySources) return []
    return historySources
      .map((source) => {
        const text = extractMarkedTextSimple(source.text)
        const split = text.split(' ')
        if (!split.length) return null

        const first = split[0]
        const last = split[split.length - 1]
        if (!first || !last) return null

        return {
          first,
          last,
          length: text.length,
          footnote: source.footnote,
        }
      })
      .filter((s) => !!s)
  }, [historySources]) as {
    first: string
    last: string
    length: number
    footnote: number
  }[]

  const { artifact } = useCaseLawQuery(artifactId, sources)

  const isLoading = (queryId && !historyItem) || !artifact?.rawHtml

  const artifactMarkIds = useMemo(() => {
    if (!artifact?.rawHtml) return []

    const markMatches = Array.from(artifact.rawHtml.matchAll(MARK_ID_REGEX))
    const markIds: number[] = []
    markMatches.forEach((match) => {
      if (match.length !== 2) return
      const markId = parseInt(match[1], 10)
      if (isNaN(markId)) return
      markIds.push(markId)
    })
    markIds.sort((a, b) => a - b)

    return markIds
  }, [artifact])

  useEffect(() => {
    // No mark to scroll to
    if (!artifact?.rawHtml || !artifact.rawHtml.match(MARK_ID_REGEX)) return

    if (!footnote || !sources.length) return

    const matchingSource = sources.find(
      (source) => source.footnote === parseInt(footnote, 10)
    )
    if (!matchingSource) return

    scrollToFootnote(String(matchingSource.footnote))
  }, [artifact, footnote, sources])

  const transformUrl = (url: string, key: string, node: Readonly<Element>) => {
    if (node.tagName === 'a' && key === 'href' && URL_REGEX.test(url)) {
      const matches = url.match(URL_REGEX)
      if (!matches || matches.length !== 2) {
        return defaultUrlTransform(url)
      }
      const artifactId = matches[1]
      const query = {
        queryId,
      }
      const search = queryString.stringify(query)
      return `${RESEARCH_ARTIFACT_PATH}/${artifactId}?${search}`
    }
    return defaultUrlTransform(url)
  }

  const prettiedHtml = useMemo(() => {
    if (!artifact?.rawHtml) return ''

    // If artifact already has a proper header, assume it is properly styled and we don't need to replace anything.
    if (artifact.rawHtml.match(/^[\s\S]{0,500}<h1>[\s\S]+<\/h1>/m)) {
      return artifact.rawHtml
    }

    const html = artifact.rawHtml
      // Pull out basic numeric headers created with whitespace around
      .replaceAll(/^ *(\d+\.{0,1} *)\n/gm, '<p><b>$1</b></p>\n\n')
      .replaceAll(/^ *([A-Z]{1}\.{0,1} *)\n/gm, '<p><b>$1</b></p>\n\n')
      // Find roman numeral sections, e.g. I. BACKGROUND
      // Regex from https://stackoverflow.com/a/267405
      .replaceAll(
        /^ *((L?X{0,3})(IX|IV|V?I{0,3})\.{0,1} *[A-Z][A-Za-z]*?\b) *\n/gm,
        '<h2>$1</h2>\n\n'
      )
      // Clean up large indents, since markdown treats 4+ spaces as a code block
      .replaceAll(/ {4,}/g, ' ')
      // Find court details before case and the case title.
      // This is definitely a bit fragile. It looks for formats like:
      // (a) In the court of appeals for California
      //      Fifth appellate district
      //      Division eight
      // (b) United States Court of Appeals
      //      First Circuit
      //      No. 12-3456
      // And then it assumes the case title is right after it
      //  {name of multiple plaintiff (sometimes with parens)} v. {name of defendant}
      //
      // ((a)|(b)) is group 1. (a) is a subgroup with its own subgroup. Same with (b). So those are groups 2-5.
      // Then the title is group 6.
      .replace(
        /((^[\s\S]*?appellate district(\s+division\s[a-z]{0,12})?)|(^[\s\S]*?circuit(\s+no.\s[0-9-]{0,10})?))?([a-z][a-z0-9(\s,.]{0,250}plaintiff[\s\S]{0,100}v\.[\s\S]{0,150}defendant[\s\S]{0,100}?\.)/im,
        '<center><b>$1</b></center>\n\n<center><h1>$6</h1></center>\n\n'
      )

      // Find sub-sections, e.g. -2-
      .replaceAll(/-\d+-/g, '<p><b>$&</b></p>\n\n')

    return html
  }, [artifact?.rawHtml])

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link className="text-xs" to="/research">
              Research
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link
              className="text-xs"
              to={`/research/${ResearchArea.USCASELAW}/${queryId}`}
            >
              US Case Law
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
      </BreadcrumbList>
    </Breadcrumb>
  )

  useEffect(() => {
    if (artifact?.caseTitle) {
      document.title = artifact.caseTitle
    }

    return () => {
      document.title = 'Harvey'
    }
  }, [artifact?.caseTitle])

  const metadata = historySources?.[0]?.metadata
  const subtitle = metadata ? (
    <div className="flex space-x-2 divide-x">
      {metadata.map((meta, i) => (
        <p key={i} className="truncate pl-2 text-xs text-muted first:pl-0">
          {meta}
        </p>
      ))}
    </div>
  ) : null

  return (
    <AppMain hasContainer>
      <AppHeader
        breadcrumbs={breadcrumbs}
        title={artifact?.caseTitle}
        subtitleComponent={subtitle}
      />
      {isLoading ? (
        <Skeleton rows={16} rowHeight="h-6" />
      ) : (
        <div className="flex items-start justify-around gap-6">
          {artifactMarkIds.length > 0 && (
            // The height calc is viewport - (1rem * 2 = vertical padding on <main>) - (104px = height of top nav)
            <div className="group sticky top-4 z-10 flex h-[calc(100vh-2rem-104px)] w-48 min-w-12 flex-col justify-center">
              <div className="flex min-h-0 flex-col rounded-lg transition group-hover:w-48 group-hover:bg-primary group-hover:shadow-[0_0_0_1px_hsl(var(--border-primary))] xl:group-hover:w-full xl:group-hover:shadow-none">
                <ScrollArea>
                  <div className="flex flex-col space-y-2 p-4">
                    <div className="invisible mb-2 shrink-0 truncate text-xs text-muted group-hover:visible lg:visible">
                      References
                    </div>
                    {artifactMarkIds.map((markId) => {
                      const footnoteId = String(markId)
                      const annotation = historySources?.find(
                        (an) => an.footnote === markId
                      )
                      const preview = extractMarkedTextSimple(annotation?.text)

                      return (
                        <button
                          key={footnoteId}
                          className="flex items-center space-x-2"
                          onClick={() => setFootnote(footnoteId)}
                        >
                          <Citation isSelected={footnoteId === footnote}>
                            {footnoteId}
                          </Citation>
                          {preview && (
                            <div className="invisible truncate text-xs group-hover:visible lg:visible">
                              {preview}
                            </div>
                          )}
                        </button>
                      )
                    })}
                  </div>
                </ScrollArea>
              </div>
            </div>
          )}
          <ReactMarkdown
            rehypePlugins={[
              rehypeRaw,
              [rehypeSanitize, MARKDOWN_SANITIZER_SCHEMA],
            ]}
            components={{
              mark: ({ children, id }) => (
                <mark id={id} className="bg-highlight/60 text-highlight">
                  {children}
                </mark>
              ),
              h1: ({ children }) => <h2>{children}</h2>,
              pre: ({ children }) => <div>{children}</div>,
            }}
            urlTransform={transformUrl}
            className="prose mb-8"
          >
            {prettiedHtml}
          </ReactMarkdown>
          {/* Empty div to center align artifact */}
          {artifactMarkIds.length > 0 && <div className="w-48" />}
        </div>
      )}
    </AppMain>
  )
}

export default Artifact
