import React, { useState, useEffect } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { FetchWorkspaces } from 'models/workspace'
import { VaultWorkflowVisibilityKind } from 'openapi/models/VaultWorkflowVisibilityKind'

import {
  displayErrorMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogContent,
  Dialog,
} from 'components/ui/dialog'
import { MultiSelect } from 'components/ui/multi-select'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { PublishWorkflow } from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultPublishWorkflow: React.FC = () => {
  const [selectedVisibility, setSelectedVisibility] = useState<
    VaultWorkflowVisibilityKind | ''
  >('')
  const [availableWorkspaces, setAvailableWorkspaces] = useState<
    { id: number; name: string }[]
  >([])
  const [workspacesLoading, setWorkspacesLoading] = useState(false)
  const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState<string[]>([])
  const [isSubmittingPublish, setIsSubmittingPublish] = useState(false)
  const [
    selectedWorkflow,
    workflowModalState,
    setWorkflowModalState,
    setWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.selectedWorkflow,
      state.workflowModalState,
      state.setWorkflowModalState,
      state.setWorkflow,
    ])
  )

  useEffect(() => {
    const fetchWorkspaces = async () => {
      setWorkspacesLoading(true)
      try {
        const data = await FetchWorkspaces()
        setAvailableWorkspaces(
          data.map((workspace) => ({
            id: workspace.id,
            name: workspace.friendlyName ?? workspace.clientName,
          }))
        )
      } catch (error) {
        console.error('Error fetching workspaces:', error)
      } finally {
        setWorkspacesLoading(false)
      }
    }

    if (selectedVisibility === VaultWorkflowVisibilityKind.WORKSPACE) {
      void fetchWorkspaces()
    }
  }, [selectedVisibility])

  const handlePublish = async () => {
    if (!selectedVisibility) {
      displayWarningMessage('Please select a visibility option.')
      return
    }
    let workspaceIds: number[] = []
    if (selectedVisibility === VaultWorkflowVisibilityKind.WORKSPACE) {
      if (selectedWorkspaceIds.length === 0) {
        displayWarningMessage('Please select at least one workspace.')
        return
      }
      workspaceIds = selectedWorkspaceIds.map(Number)
    }
    setIsSubmittingPublish(true)
    try {
      const response = await PublishWorkflow(
        selectedWorkflow!.id,
        selectedVisibility,
        workspaceIds
      )
      displaySuccessMessage('Workflow published successfully')
      setWorkflow(response.workflow)
      setWorkflowModalState(WorkflowModalState.Selector)
    } catch (error) {
      displayErrorMessage('Failed to publish workflow')
    } finally {
      setIsSubmittingPublish(false)
    }
  }

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.Publish}
      onOpenChange={(open) => {
        if (!open) {
          setWorkflowModalState(WorkflowModalState.None)
        }
      }}
    >
      <DialogContent className="bg-secondary sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Publish Workflow</DialogTitle>
          <DialogDescription>
            Select visibility and{' '}
            {selectedVisibility === VaultWorkflowVisibilityKind.WORKSPACE
              ? 'select workspaces'
              : 'publish the workflow'}
            .
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <label htmlFor="visibility" className="block text-sm font-medium">
              Visibility
            </label>
            <Select
              value={selectedVisibility}
              onValueChange={(value) => {
                setSelectedVisibility(value as VaultWorkflowVisibilityKind)
                setSelectedWorkspaceIds([])
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select visibility" />
              </SelectTrigger>
              <SelectContent>
                {[
                  VaultWorkflowVisibilityKind.WORKSPACE,
                  VaultWorkflowVisibilityKind.PUBLIC,
                ].map((visibility) => (
                  <SelectItem key={visibility} value={visibility}>
                    {visibility}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {selectedVisibility === VaultWorkflowVisibilityKind.WORKSPACE && (
            <div>
              <label htmlFor="workspaces" className="block text-sm font-medium">
                Select Workspaces
              </label>
              <MultiSelect
                placeholder="Select workspaces"
                sortedEntries={availableWorkspaces.map((workspace) => ({
                  text: workspace.name,
                  value: workspace.id.toString(),
                }))}
                selectedValues={selectedWorkspaceIds}
                setSelectedValues={(values) => setSelectedWorkspaceIds(values)}
                className="mt-1 w-full"
                disabled={workspacesLoading}
              />
            </div>
          )}

          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => {
                setWorkflowModalState(WorkflowModalState.Selector)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handlePublish}
              disabled={
                isSubmittingPublish ||
                !selectedVisibility ||
                (selectedVisibility === VaultWorkflowVisibilityKind.WORKSPACE &&
                  selectedWorkspaceIds.length === 0)
              }
              isLoading={isSubmittingPublish}
            >
              Publish
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultPublishWorkflow
