import * as Sentry from '@sentry/browser'
import { Query, QueryKey } from '@tanstack/react-query'
import { HTTPError } from 'ky'

import { FetchHistoryItem } from 'models/fetchers/history-fetcher'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { SourceType } from 'openapi/models/SourceType'
import { VaultEventRunType } from 'openapi/models/VaultEventRunType'
import Services from 'services'
import { Maybe } from 'types'
import { HistoryItem } from 'types/history'

import { parseIsoString } from 'utils/utils'

import { VaultEventV2 } from 'components/vault/utils/vault'
import { mapVaultEventV2ToV1Metadata } from 'components/vault/utils/vault-helpers'

const FetchVaultHistoryItemV2 = async ({
  id,
  throwOnError = false,
  maxRetryCount = 3,
  skipSources = false,
}: {
  id: Maybe<string>
  throwOnError?: boolean
  maxRetryCount?: number
  skipSources?: boolean
}): Promise<HistoryItem | null> => {
  if (!id) {
    return null
  }

  const searchParams = new URLSearchParams()
  if (skipSources) {
    searchParams.append('skip_sources', 'true')
  }

  const searchParamsString = searchParams.toString()

  return Services.Backend.Get<VaultEventV2>(
    `vault/v2/history/${id}${
      searchParamsString ? `?${searchParamsString}` : ''
    }`,
    {
      throwOnError: true,
      maxRetryCount: maxRetryCount,
    }
  )
    .then((vaultEventV2) => {
      const cellIdToDocumentId = new Map(
        vaultEventV2.cells.map((cell) => [cell.id, cell.fileId])
      )
      const columnIdToQuestionId = new Map(
        vaultEventV2.columns.map((column) => [
          column.id,
          String(column.displayId),
        ])
      )
      const cellIdToColumnId = new Map(
        vaultEventV2.cells.map((cell) => [cell.id, cell.vaultColumnId])
      )
      const sources = vaultEventV2.sources.map((source) => ({
        ...source,
        documentId: cellIdToDocumentId.get(source.cellId) ?? '',
        questionId:
          columnIdToQuestionId.get(cellIdToColumnId.get(source.cellId) ?? '') ??
          '',
        sourceType: SourceType.PDF_KIT,
        annotations: [],
        page: source.pageNumber,
        documentUrl: '', // We don't have the document URL for event v2 sources
      }))
      return {
        ...vaultEventV2,
        id: vaultEventV2.eventId.toString(), // This is a number in the backend
        userId: vaultEventV2.eventCreatorEmail, // userId of the event v1 is the creator's email
        status: vaultEventV2.eventStatus,
        query:
          vaultEventV2.runs.find((run) => run.runType === VaultEventRunType.NEW)
            ?.query ?? '',
        response: '',
        kind: vaultEventV2.eventKind,
        created: parseIsoString(vaultEventV2.eventCreatedAt),
        updatedAt: parseIsoString(vaultEventV2.eventUpdatedAt),
        vaultFolderId: vaultEventV2.vaultFolderId,
        numFiles: new Set(vaultEventV2.cells.map((cell) => cell.fileId)).size,
        numQuestions: vaultEventV2.columns.length,
        metadata: mapVaultEventV2ToV1Metadata(vaultEventV2, sources),
        sources: sources,
      } as HistoryItem
    })
    .catch((e) => {
      if (throwOnError) {
        throw e
      } else {
        console.warn('Failed to fetch history item', { e, id })
        return null
      }
    })
}

const vaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  isV2 = false,
  throwOnError = false,
  maxRetryCount = 3,
  skipSources = false,
}: {
  id: Maybe<string>
  vaultFolderId: string
  isV2?: boolean
  throwOnError: boolean
  maxRetryCount?: number
  skipSources?: boolean
}) => ({
  queryKey: [HarvQueryKeyPrefix.VaultHistoryItemQuery, id, skipSources],
  queryFn: async () => {
    let historyItem: HistoryItem | null
    const fetchHistoryItemUsingOldEndpoint = async () => {
      return await FetchHistoryItem({
        id,
        throwOnError,
        maxRetryCount,
        useVaultEndpoint: true,
        skipSources,
      })
    }
    if (isV2) {
      try {
        historyItem = await FetchVaultHistoryItemV2({
          id,
          throwOnError,
          maxRetryCount,
          skipSources,
        })
      } catch (e) {
        if (e instanceof HTTPError && e.response.status === 400) {
          // Bad request means that we should use the old endpoint
          historyItem = await fetchHistoryItemUsingOldEndpoint()
        } else {
          throw e
        }
      }
    } else {
      historyItem = await fetchHistoryItemUsingOldEndpoint()
    }
    if (historyItem && historyItem.vaultFolderId !== vaultFolderId) {
      throw new Error(
        `History item ${historyItem.id} does not belong to the vault project ${vaultFolderId}`
      )
    }
    return historyItem
  },
  initialData: null,
})

export const useVaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  isV2 = false,
  isEnabled = true,
  throwOnError = false,
  refetchInterval,
  skipSources = false,
}: {
  id: Maybe<string>
  vaultFolderId: string
  isV2?: boolean
  isEnabled?: boolean
  throwOnError?: boolean
  refetchInterval?: (
    query: Query<HistoryItem | null, Error, HistoryItem | null, QueryKey>
  ) => number | false | undefined
  skipSources?: boolean
}) => {
  const {
    isPending,
    error,
    data: historyItem,
    isLoading,
    isFetching,
    isFetched,
  } = useWrappedQuery({
    enabled: !!id && isEnabled,
    refetchInterval,
    refetchOnWindowFocus: false,
    // Set maxRetryCount to 0 because we will handle retries in the useQuery hook
    ...vaultHistoryItemQuery({
      id,
      isV2,
      vaultFolderId,
      throwOnError,
      maxRetryCount: 0,
      skipSources,
    }),
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { historyItem, isPending, isLoading, isFetching, isFetched, error }
}
