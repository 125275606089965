import React, { useMemo, useState } from 'react'
import { useMount } from 'react-use'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { getLibraryTeamLabel } from 'models/helpers/library-helper'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import { displaySuccessMessage } from 'utils/toast'

import { useExamplesFilterComponents } from './examples-filter-components'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import SelectVisibleFilter, {
  TabConfig,
} from 'components/filter/instances/select-visible-filter'
import { Dialog } from 'components/ui/dialog'

import { getExamplesTableColumns } from './examples-table-columns'
import { LibraryFilterRow } from './library-filter-row'
import {
  FilterFunction,
  LibraryFilterKey,
  useExampleFilterStore,
} from './library-filter-store'
import { LibrarySaveExampleDialog } from './library-save-example'
import { ExampleStatus } from './library-status'
import { LibraryTable } from './library-table'
import { Example, LibraryItem, LibraryItemKind } from './library-types'
import { useLibraryData } from './use-library-data'
import { useVisibilityScope } from './use-visibility-scope'

const VISIBLE_SCOPES = [
  LibraryVisbilityScope.WORKSPACE,
  LibraryVisbilityScope.HARVEY,
]
type VisibleScope = (typeof VISIBLE_SCOPES)[number]

const TAB_FILTER_CONFIG: Record<
  VisibleScope,
  Pick<TabConfig, 'label' | 'tooltip'>
> = {
  [LibraryVisbilityScope.WORKSPACE]: {
    label: getLibraryTeamLabel,
    tooltip: 'Examples created by your admin for everyone',
  },
  [LibraryVisbilityScope.HARVEY]: {
    label: 'Harvey',
    tooltip: 'Examples created by Harvey',
  },
}

const LibraryExamples: React.FC = () => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  useMount(() => {
    Services.HoneyComb.Record({
      metric: 'ui.examples_page_visited',
      workspace_id: userInfo.workspace.id,
      user_id: userInfo.id,
    })
  })

  const { items, isLoading, refetchItems } = useLibraryData(
    LibraryItemKind.EXAMPLE
  )

  const [saveLoading, setSaveLoading] = useState(false)

  const [
    filterFunctions,
    registerFilter,
    filterValues,
    setFilterValue,
    setFilterValues,
  ] = useExampleFilterStore(
    useShallow((s) => [
      s.filterFunctions,
      s.registerFilter,
      s.filterValues,
      s.setFilterValue,
      s.setFilterValues,
    ])
  )

  const [currentTab, setCurrentTab] = useVisibilityScope({
    initialScope: VISIBLE_SCOPES[0],
    items,
    queryKey: LibraryFilterKey.VISIBILITY_SCOPE,
    filterValues,
    setFilterValue,
  })

  const [editItem, setEditItem] = React.useState<Example | null>(null)

  const filterComponents = useExamplesFilterComponents({
    items: items as Record<string, Example>, // TODO: Cast type in useLibraryData
  })

  const filterKeys = useMemo(
    () => [
      ...filterComponents.map(({ filterKey }) => filterKey),
      LibraryFilterKey.VISIBILITY_SCOPE,
    ],
    [filterComponents]
  )

  const filteredItems = useMemo(
    () =>
      Object.values(items).filter(
        (item) => item.visibilityScope === currentTab
      ),
    [items, currentTab]
  )

  if (_.isNil(userInfo) || !userInfo.IsLibraryUser) {
    return null
  }

  // TODO: Move this to an api service
  const handleSaveUpdate = async (
    params: Partial<
      Pick<
        Example,
        | 'name'
        | 'categories'
        | 'practiceAreas'
        | 'documentTypes'
        | 'visibilityScope'
      >
    >
  ) => {
    const { name, documentTypes, categories, practiceAreas } = params

    if (!editItem) return false
    setSaveLoading(true)
    let result = false
    try {
      const example = await Services.Backend.Patch<Example>(
        `library/example/${editItem.id}`,
        {
          name: name?.trim(),
          sourceEventId: editItem.eventId,
          categories,
          documentTypes,
          practiceAreas,
        }
      )

      if (!_.isEmpty(example)) {
        Services.HoneyComb.Record({
          metric: `ui.library_example_edited`,
          item_id: editItem.id,
          task_type: editItem.eventKind,
          starred: editItem.starred,
          workspace_id: editItem.workspaceId,
          user_id: editItem.userId,
          visibility_scope: editItem.visibilityScope,
          categories: editItem.categories,
          practice_areas: editItem.practiceAreas,
          document_types: editItem.documentTypes,
        })
        trackEvent('Library Item Edited', {
          item_id: editItem.id,
          event_id: editItem.eventId,
          kind: LibraryItemKind.EXAMPLE,
          task_type: editItem.eventKind,
          visibility_scope: editItem.visibilityScope,
          categories: editItem.categories,
          practice_areas: editItem.practiceAreas,
          document_types: editItem.documentTypes,
          starred: editItem.starred,
        })
        displaySuccessMessage('Saved example successfully', 1)
        setEditItem(null)
        result = true
        void refetchItems()
      }
    } finally {
      setSaveLoading(false)
    }
    return result
  }

  return (
    <AppMain
      className="container mx-auto flex h-full flex-col space-y-4 py-4"
      data-testid="examples-container"
    >
      <AppHeader
        breadcrumbs={<RouterBreadcrumbs removeParams={filterKeys} />}
        title="Examples"
        subtitle="Browse examples to understand what Harvey can accomplish"
        actions={
          <div className="ml-auto pl-2 lg:hidden">
            <ExampleStatus fetchUpdate={refetchItems} />
          </div>
        }
      />
      <Dialog
        open={!!editItem}
        onOpenChange={(open) => {
          if (!open) {
            setEditItem(null)
          }
        }}
      >
        {editItem && (
          <LibrarySaveExampleDialog
            handleSaveExample={handleSaveUpdate}
            saveLoading={saveLoading}
            editExample={{
              id: editItem.id,
              name: editItem.name,
              documentTypes: editItem.documentTypes,
              categories: editItem.categories,
              practiceAreas: editItem.practiceAreas,
              visibilityScope: editItem.visibilityScope,
            }}
          />
        )}
      </Dialog>
      <div className="flex shrink-0 space-x-2 lg:justify-between">
        <div className="flex grow space-x-2">
          <div className="shrink-0">
            <SelectVisibleFilter
              id="visible-examples-filter"
              filterKey={LibraryFilterKey.VISIBILITY_SCOPE}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabs={VISIBLE_SCOPES.map((scope) => ({
                value: scope,
                ...TAB_FILTER_CONFIG[scope],
              }))}
            />
          </div>
          <div className="hidden lg:block">
            <ExampleStatus fetchUpdate={refetchItems} />
          </div>
        </div>
        <LibraryFilterRow
          type={LibraryItemKind.EXAMPLE}
          workspace={userInfo.workspace}
          // TODO: Fix this type
          filterComponents={filterComponents as any}
          filterKeys={filterKeys}
          filterStore={{
            registerFilter,
            filterValues,
            setFilterValue,
            setFilterValues,
          }}
        />
      </div>
      <LibraryTable
        getColumns={(onDeleteItem, onHideItem) =>
          getExamplesTableColumns({
            currentTab,
            userInfo,
            onDeleteItem,
            onEditItem: setEditItem as (item: LibraryItem) => void,
            onHideItem,
          })
        }
        isLoading={isLoading}
        items={filteredItems}
        filterFunctions={
          Object.values(filterFunctions) as FilterFunction<LibraryItem>[]
        }
        kind={LibraryItemKind.EXAMPLE}
        visibilityScope={currentTab}
      />
    </AppMain>
  )
}

export default LibraryExamples
