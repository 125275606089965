import React, { useRef, useState, MutableRefObject } from 'react'
import { useUnmount } from 'react-use'

import { ListFilter } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import useHarveySocket from 'utils/use-harvey-socket'
import { isUserInputEmpty } from 'utils/utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import BasicFeedback from 'components/common/feedback'
import PromptButtonGroup from 'components/library/prompt-button-group'
import FeedbackTax from 'components/research/FeedbackPWC/feedback-tax'
import { RESEARCH_SAVE_PROMPT_TOOLTIP_HELP } from 'components/research/constants'
import { ResearchDatePicker } from 'components/research/date-picker'
import Disclaimer from 'components/research/disclaimer'
import Explorer from 'components/research/explorer'
import Help from 'components/research/help'
import InputCard, { maxQueryLength } from 'components/research/input-card'
import { isDoneStreaming, getTitle } from 'components/research/research-helpers'
import { ResearchResponse } from 'components/research/research-response'
import { ResearchSheet } from 'components/research/research-sheet'
import { useResearchStore } from 'components/research/research-store'
import { Sources } from 'components/research/sources'
import { Toggle } from 'components/ui/toggle'

interface ResearchPageDetailProps {
  isTax: boolean
  historyIdRef: MutableRefObject<string | undefined>
  eventKind: EventKind
  researchArea: ResearchArea
  taxonomy: ResearchFilter[]
  allFiltersFlattened: ResearchFilter[]
}

const ResearchPageDetail = ({
  isTax,
  historyIdRef,
  eventKind,
  researchArea,
  allFiltersFlattened,
  taxonomy,
}: ResearchPageDetailProps) => {
  const userInfo = useAuthUser()

  const selectedFilters = useResearchStore(useShallow((s) => s.selectedFilters))
  const setSelectedFilters = useResearchStore(
    useShallow((s) => s.setSelectedFilters)
  )
  const isLoading = useResearchStore(useShallow((s) => s.isLoading))
  const queryId = useResearchStore(useShallow((s) => s.queryId))
  const dateRange = useResearchStore(useShallow((s) => s.dateRange))
  const query = useResearchStore(useShallow((s) => s.query))
  const feedback = useResearchStore(useShallow((s) => s.feedback))
  const response = useResearchStore(useShallow((s) => s.response))
  const sources = useResearchStore(useShallow((s) => s.sources))
  const headerText = useResearchStore(useShallow((s) => s.headerText))
  const setTask = useResearchStore((state) => state.setTask)
  const setFeedback = useResearchStore((state) => state.setFeedback)
  const setQuery = useResearchStore((state) => state.setQuery)
  const setQueryPreview = useResearchStore((state) => state.setQueryPreview)
  const resetState = useResearchStore((state) => state.resetState)
  const setResearchArea = useResearchStore((state) => state.setResearchArea)

  const selectedClientMatter = useClientMattersStore(
    useShallow((s) => s.selectedClientMatter)
  )
  const setClientMatterSelectDisabled = useClientMattersStore(
    (state) => state.setClientMatterSelectDisabled
  )

  const {
    setQueryViewed,
    recordQueryCancel,
    recordQuerySubmitted,
    recordQueryCompletion,
  } = useQueryAnalytics(eventKind)

  const isDatePickerEnabled = researchArea === ResearchArea.FRANCECASELAW
  const isPromptsEnabled =
    userInfo.IsLibraryUser && researchArea === ResearchArea.EDGAR
  const isSavePromptDisabled = isUserInputEmpty([query]) || isLoading
  const isFeedbackVisible =
    isDoneStreaming(response, headerText) && (isTax || !feedback?.closed)

  const [isExplorerOpen, setIsExplorerOpen] = useState(true)
  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const onAuthCallback = (newQueryId: string) => {
    if (newQueryId && historyIdRef.current !== newQueryId) {
      // by using replaceState we are able to update the parameter without triggering a re-render and causing useQuery to re-fetch
      const currentUrl = new URL(window.location.href)
      const currentParams = currentUrl.searchParams.toString()
      const newUrl = `/research/${researchArea}/${newQueryId}${
        currentParams ? '?' + currentParams : ''
      }`
      window.history.replaceState(null, getTitle(researchArea), newUrl)
      historyIdRef.current = newQueryId
      setQueryViewed()
    }
  }

  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: 'research/query',
    setter: setTask,
    endCallback: () => {
      setClientMatterSelectDisabled(false)
      const sourceDocumentIds = new Set(sources.map((s) => s.documentId))
      recordQueryCompletion({ num_sources: sourceDocumentIds.size })
    },
  })

  const handleCancel = () => {
    recordQueryCancel()
    sendCancelRequest()
  }

  const handleAskHarvey = () => {
    recordQuerySubmitted({
      event_kind: eventKind,
    })
    setFeedback(null)
    const filterIds = selectedFilters.map((filter) => filter.id)
    const client_matter_id = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null
    setResearchArea(researchArea)

    setClientMatterSelectDisabled(true)
    initSocketAndSendQuery({
      query,
      additionalAuthParams: {
        filter_ids: filterIds,
        client_matter_id,
      },
      additionalRequestParams: {
        // adding source_event_id if we have a query id
        // https://www.notion.so/harveyai/Make-sure-we-keep-honoring-event_source_id-04edcaaae0d8440a9b51808aef7af349?pvs=4
        source_event_id: queryId,
        date_range: dateRange,
      },
      onAuthCallback,
    })
  }

  useUnmount(() => {
    handleCancel()
    resetState()
  })

  return (
    <AppMain hasContainer id="research-container">
      <ResearchSheet />
      <div className="h-full space-y-4">
        <InputCard
          inputRef={inputRef}
          onAskHarvey={handleAskHarvey}
          taxonomy={taxonomy}
          area={researchArea}
        >
          <div className="absolute left-4 top-[124px] flex items-center space-x-2">
            <Toggle
              size="sm"
              pressed={isExplorerOpen}
              onPressedChange={() => setIsExplorerOpen(!isExplorerOpen)}
            >
              <ListFilter size={16} className="mr-1" />
              <span className="text-xs">Filters</span>
            </Toggle>
            {isDatePickerEnabled && <ResearchDatePicker />}
            {isPromptsEnabled && (
              <PromptButtonGroup
                query={query}
                setQuery={(q) => {
                  setQuery(q)
                  setTimeout(() => inputRef.current?.focus())
                }}
                setQueryPreview={setQueryPreview}
                queryLimit={maxQueryLength}
                eventKind={eventKind}
                loadDisabled={isLoading}
                saveDisabled={isSavePromptDisabled}
                enabledTooltipText={RESEARCH_SAVE_PROMPT_TOOLTIP_HELP}
              />
            )}
          </div>
          <div className="h-56 border-t">
            <Explorer
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              isLoading={isLoading}
              isOpen={isExplorerOpen}
              taxonomy={taxonomy}
              researchArea={researchArea}
            />
          </div>
        </InputCard>
        {isFeedbackVisible &&
          queryId &&
          (isTax ? (
            <FeedbackTax
              queryId={queryId}
              selectedFilters={selectedFilters}
              allFilterNodes={allFiltersFlattened}
            />
          ) : (
            <BasicFeedback
              queryId={queryId}
              saveFeedback={setFeedback}
              className="mx-0"
            />
          ))}
        <ResearchResponse
          area={researchArea}
          sendCancelRequest={handleCancel}
        />
        <Sources area={researchArea} />
        {isTax && isLoading && <Disclaimer />}
        <div className="h-8" />
      </div>
      <div className="fixed bottom-2 right-2 z-10">
        <Help area={researchArea} />
      </div>
    </AppMain>
  )
}

export default ResearchPageDetail
