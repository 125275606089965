import React from 'react'

import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import ExplorerItem from 'components/research/explorer-item'
import { ScrollArea } from 'components/ui/scroll-area'

interface ExplorerColumnProps {
  taxonomy: ResearchFilter[]
  filters: ResearchFilter[]
  depth: number
  openFilters: ResearchFilter[]
  setOpenFilters: (filters: ResearchFilter[]) => void
  selectedFilters: ResearchFilter[]
  setSelectedFilters: (filters: ResearchFilter[]) => void
  isLoading: boolean
  researchArea: ResearchArea
}

const ExplorerColumn: React.FC<ExplorerColumnProps> = ({
  taxonomy,
  filters,
  depth,
  openFilters,
  setOpenFilters,
  selectedFilters,
  setSelectedFilters,
  isLoading,
  researchArea,
}) => {
  return (
    <ScrollArea className="h-full w-72 border-r">
      <div className="h-2" />
      {filters.map((filter) => (
        <ExplorerItem
          taxonomy={taxonomy}
          filter={filter}
          key={filter.id}
          depth={depth}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          isLoading={isLoading}
          researchArea={researchArea}
        />
      ))}
      <div className="h-2" />
    </ScrollArea>
  )
}

export default ExplorerColumn
