import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import _ from 'lodash'
import { ChevronDown, Waypoints } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { TagScope } from 'openapi/models/TagScope'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TaskType } from 'utils/task'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Icon } from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING } from 'components/vault/utils/use-document-classification-store'
import {
  DocumentClassificationAnalyticsData,
  GenerateNNResponseProps,
  projectsPath,
  queriesPath,
  QueryQuestions,
  REMOVE_PARAMS,
} from 'components/vault/utils/vault'
import { VaultColumn } from 'components/vault/utils/vault-fetcher'
import { getReadyToQueryFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

const VaultWorkflowBuilder = ({
  generateNNResponse,
}: {
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const { projectId } = useParams()
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const [
    currentProjectMetadata,
    folderIdToVaultFolder,
    pendingQueryFileIds,
    questionsLimit,
    filesLimit,
    maxQuestionCharacterLength,
    minQuestionCharacterLength,
    setReviewTask,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentProjectMetadata,
      state.folderIdToVaultFolder,
      state.pendingQueryFileIds,
      state.questionsLimit,
      state.filesLimit,
      state.maxQuestionCharacterLength,
      state.minQuestionCharacterLength,
      state.setReviewTask,
    ])
  )
  const [selectedWorkflow, workflowModalState, setWorkflowModalState] =
    useVaultWorkflowStore(
      useShallow((state) => [
        state.selectedWorkflow,
        state.workflowModalState,
        state.setWorkflowModalState,
      ])
    )

  const [selectedColumns, setSelectedColumns] = useState<VaultColumn[]>([])
  useEffect(() => {
    setSelectedColumns(selectedWorkflow?.columns || [])
  }, [selectedWorkflow])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleColumnSelect = (column: VaultColumn) => {
    setSelectedColumns((prev) =>
      prev.includes(column)
        ? prev.filter((c) => c.id !== column.id)
        : [...prev, column]
    )
  }

  // TODO: Remove this after we have a new workflow review
  const handleLegacyVaultReview = async () => {
    if (!projectId || !selectedWorkflow) {
      setIsSubmitting(false)
      return
    }

    const readyToQueryFileIds = getReadyToQueryFileIds(
      currentProjectMetadata,
      pendingQueryFileIds,
      folderIdToVaultFolder
    )

    const documentClassificationTags = readyToQueryFileIds.flatMap((fileId) => {
      const file = currentProjectMetadata.descendantFiles?.find(
        (file) => file.id === fileId
      )
      return (
        file?.tags.filter(
          (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
        ) ?? []
      )
    })
    const documentClassificationData = documentClassificationTags.reduce(
      (acc, tag) => {
        const existingEntry = acc.find(
          (entry) => entry.typeDocumentClassification === tag.name
        )
        const tagKey =
          tag.name as keyof typeof DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING
        const groupingDocumentClassification =
          DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING[tagKey]
        if (existingEntry) {
          existingEntry.numDocuments++
        } else {
          acc.push({
            numDocuments: 1,
            typeDocumentClassification: tag.name,
            groupingDocumentClassification: groupingDocumentClassification,
          })
        }
        return acc
      },
      [] as DocumentClassificationAnalyticsData[]
    )

    const selectedQuestionsWithNewIds = selectedColumns
      .toSorted((a, b) => a.displayId - b.displayId)
      .map((column) => ({
        id: String(column.displayId),
        text: column.fullText,
        header: column.header,
        columnDataType: column.dataType,
      })) as QueryQuestions[]

    const selectedWorkflowColumnIds = selectedColumns.map((column) => column.id)

    await generateNNResponse({
      requestType: 'new',
      queryId: undefined,
      query: selectedWorkflow.name,
      folderId: projectId,
      fileIds: readyToQueryFileIds,
      documentClassificationAnalyticsData: documentClassificationData,
      questions: selectedQuestionsWithNewIds,
      questionsNotAnswered: [],
      existingQuestions: [],
      questionsLimit: questionsLimit,
      filesLimit: filesLimit,
      maxQuestionCharacterLength: maxQuestionCharacterLength,
      minQuestionCharacterLength: minQuestionCharacterLength,
      dryRun: false,
      isWorkflowRepsWarranties: false,
      existingColumnOrder: [],
      workflowId: selectedWorkflow.id,
      selectedWorkflowColumnIds: selectedWorkflowColumnIds,
      onAuthCallback: (queryId) => {
        setReviewTask({
          // We always query the new vault v2 endpoint for review task
          isFromHistory: userInfo.IsVaultV2User,
          queryId: queryId,
          query: selectedWorkflow.name,
          isLoading: true,
          headerText: 'Processing',
          taskType: TaskType.VAULT_REVIEW,
          vaultFolderId: projectId,
          numFiles: readyToQueryFileIds.length,
          fileIds: readyToQueryFileIds,
          startedAt: new Date(),
          followUpQueries: [],
          questions: selectedQuestionsWithNewIds,
          questionsNotAnswered: [],
          dryRun: false,
          isWorkflowRepsWarranties: false,
          creatorUserEmail: userInfo.id,
        })
        setWorkflowModalState(WorkflowModalState.None)
        setIsSubmitting(false)
        const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}${queryId}`
        navigate(newPath, {}, REMOVE_PARAMS)
      },
    })
  }

  const handleCreateWorkflow = async () => {
    setIsSubmitting(true)
    await handleLegacyVaultReview()
  }

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.Builder}
      onOpenChange={(open) =>
        setWorkflowModalState(
          open ? WorkflowModalState.Builder : WorkflowModalState.None
        )
      }
    >
      <DialogContent
        className="bg-secondary sm:max-w-[800px]"
        innerClassName="flex flex-col"
        hasContainer={false}
      >
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              <Icon icon={Waypoints} />
              {selectedWorkflow?.name}
            </div>
          </DialogTitle>
          <DialogDescription className="text-left">
            {selectedWorkflow?.description}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mb-2 flex max-h-[60vh] flex-col">
          {selectedWorkflow?.columns.map((column) => (
            <div
              key={column.id}
              className="flex items-start space-x-2 py-3 pl-1 pr-4 hover:bg-secondary-hover"
            >
              <Checkbox
                id={column.id}
                checked={selectedColumns.includes(column)}
                onCheckedChange={() => handleColumnSelect(column)}
                className="mt-0.5"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor={column.id}
                className="flex w-full cursor-pointer flex-col items-start"
              >
                <div className="flex w-full items-baseline justify-between gap-2">
                  <p>{column.header}</p>
                  <Button
                    variant="unstyled"
                    className="-my-2.5 flex items-center gap-2 hover:bg-button-secondary-hover"
                  >
                    <p className="text-muted">
                      {_.capitalize(column.dataType.replace(/_/g, ' '))}
                    </p>
                    <Icon icon={ChevronDown} className="text-muted" />
                  </Button>
                </div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p className="line-clamp-2 text-left text-muted">
                      {column.fullText}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-sm">
                    {column.fullText}
                  </TooltipContent>
                </Tooltip>
              </label>
            </div>
          ))}
        </ScrollArea>
        <div className="flex justify-end gap-2">
          <Button
            variant="outline"
            onClick={() => setWorkflowModalState(WorkflowModalState.None)}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={handleCreateWorkflow}
          >
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultWorkflowBuilder
