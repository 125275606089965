import React, { memo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'

import { File, X } from 'lucide-react'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipPortal,
} from 'components/ui/tooltip'
import { FileToUpload } from 'components/vault/utils/vault'

type FileItemProps = {
  index: number
  style: React.CSSProperties
  data: {
    filesToUpload: FileToUpload[]
    isSubmitting: boolean
    onFileDelete: (file: FileToUpload) => void
  }
}

const FileItem = ({ index, style, data }: FileItemProps) => {
  const { filesToUpload, isSubmitting, onFileDelete } = data
  const file = filesToUpload[index]
  return (
    <div
      className="flex h-10 w-full items-center justify-between border-b py-2 pl-1 pr-4 transition last:border-b-0 hover:bg-secondary-hover"
      style={style}
    >
      <div className="mr-8 flex w-full items-center text-left">
        <Icon icon={File} className="mr-1" />
        <Tooltip>
          <TooltipTrigger>
            <p className="truncate text-start text-xs">{file.name}</p>
          </TooltipTrigger>
          <TooltipContent side="top">{file.name}</TooltipContent>
        </Tooltip>
      </div>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="xsIcon"
            disabled={isSubmitting}
            onClick={() => onFileDelete(file)}
            data-testid="vault-folder-upload-files--delete-file"
            className="shrink-0 hover:bg-button-secondary-hover"
          >
            <Icon icon={X} size="small" />
          </Button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="top">{file.name}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </div>
  )
}

const VaultUploadedFileList = ({
  height,
  filesToUpload,
  isSubmitting,
  onFileDelete,
}: {
  height: number
  filesToUpload: FileToUpload[]
  isSubmitting: boolean
  onFileDelete: (file: FileToUpload) => void
}) => {
  return (
    <AutoSizer disableHeight>
      {({ width }: { width: number }) => {
        return (
          <List
            width={width}
            height={height}
            itemCount={filesToUpload.length}
            itemSize={40}
            overscanCount={5}
            itemData={{ filesToUpload, isSubmitting, onFileDelete }}
            className="virtualized-scrollbar"
          >
            {FileItem}
          </List>
        )
      }}
    </AutoSizer>
  )
}

export default memo(VaultUploadedFileList)
