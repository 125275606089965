import React, { useState } from 'react'

import { Table } from 'antd'
import type { ColumnType } from 'antd/lib/table/interface'
import _, { isEmpty } from 'lodash'

import { type Event } from 'models/event'
import { useExamplesQuery } from 'models/queries/use-examples-query'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import htmlToPlainText from 'utils/html-to-plain-text'
import { getRouteForEvent } from 'utils/routing'
import {
  getQueryForDisplay,
  useAvailableTaskLabelLookup,
} from 'utils/task-definitions'

import { AppHeader } from 'components/common/app-header'
import { AppHeaderActions } from 'components/common/app-header-actions'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import SearchInput from 'components/ui/search-input'

import './Examples.css'

// The component
const Examples = (): JSX.Element => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()
  const taskLabelLookup = useAvailableTaskLabelLookup(userInfo)
  const {
    examplesData: { examples, useCases, documentTypes },
    isExamplesPending,
  } = useExamplesQuery(taskLabelLookup)

  const fetchedKinds = new Set(
    examples.map((example) => example.kind.toString())
  )

  const columns: Array<ColumnType<Event>> = [
    {
      title: 'Type',
      dataIndex: 'kind',
      ellipsis: true,
      className: 'ExamplesTable-kind',

      width: '10%',
      align: 'left',
      render: (kind) => taskLabelLookup[kind],
      filterSearch: true,
      filters: _.sortBy(
        Object.keys(taskLabelLookup)
          .filter((k) => fetchedKinds.has(k))
          .reduce((acc: { text: string; value: string }[], k: string) => {
            const text = taskLabelLookup[k]
            const index = acc.findIndex((item) => item.text === text)

            if (index === -1) {
              acc.push({ text, value: k })
            }

            return acc
          }, []),
        'text'
      ),
      onFilter: (value: any, record: Event): boolean => record.kind === value,
    },
    {
      title: 'Task',
      className: 'ExamplesTable-task',
      render: (record) => (
        <div data-testid="task" className="flex space-x-2">
          <div className="line-clamp-1 w-1/2">{getQueryForDisplay(record)}</div>
          <div className="line-clamp-1 w-1/2 text-muted">
            {htmlToPlainText(record.response)}
          </div>
        </div>
      ),
    },
    {
      title: 'Document type',
      dataIndex: 'documentType',
      ellipsis: true,

      width: '15%',
      align: 'right',
      filterSearch: true,
      filters: documentTypes.map((v: string) => ({ text: v, value: v })),
      onFilter: (value: any, record: Event): boolean =>
        record.documentType === value,
    },
    {
      title: 'Practice area',
      dataIndex: 'useCase',
      ellipsis: true,
      className: 'ExamplesTable-use-case',

      width: '15%',
      align: 'right',
      filterSearch: true,
      filters: useCases.map((v: string) => ({ text: v, value: v })),
      onFilter: (value: any, record: Event): boolean =>
        record.useCase === value,
    },
  ]

  const [inputText, setInputText] = useState('')
  const [searchResults, setSearchResults] = useState<Event[]>()

  const onSearch = (input: string): void => {
    setInputText(input)

    const cleanQuery = input.trim().toLowerCase()
    if (cleanQuery.length === 0) {
      setSearchResults([])
      return
    }

    setSearchResults(
      examples.filter((e) => {
        return (
          (e.query ?? '').toLowerCase().includes(cleanQuery) ||
          e.response.toLowerCase().includes(cleanQuery) ||
          taskLabelLookup[e.kind].toLowerCase().includes(cleanQuery) ||
          (e.useCase ?? '').toLowerCase().includes(cleanQuery) ||
          (e.documentType ?? '').toLowerCase().includes(cleanQuery)
        )
      })
    )
  }

  return (
    <AppMain className="Examples" data-testid="examples-container" hasContainer>
      <AppHeader
        title="Examples"
        subtitle="Browse examples to understand what Harvey can accomplish"
        actions={
          <div>
            <AppHeaderActions
              // TODO(Adam): should we worry about the reset button here? the columns all control their own filtering and I'm guessing we will migrate away from this table anyways (Antd)
              showReset={false}
              handleReset={_.noop}
              resetDisabled
            />
            <SearchInput value={inputText} setValue={onSearch} withIcon />
          </div>
        }
      />
      <Table
        className="ExamplesTable h-[731px] rounded-md border"
        columns={columns}
        dataSource={isEmpty(inputText.trim()) ? examples : searchResults}
        loading={examples.length === 0 && isExamplesPending}
        rowKey="id"
        scroll={{
          scrollToFirstRowOnChange: true,
        }}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onRow={(record) => ({
          onClick: () => {
            navigate(getRouteForEvent(record, userInfo!))
          },
        })}
      />
    </AppMain>
  )
}

// Exports
export default Examples
