import React, { useMemo, useState } from 'react'
import { useMount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  DatabaseSource,
  isTaxKnowledgeSource,
} from 'components/assistant-v2/utils/assistant-knowledge-sources'
import Explorer from 'components/research/explorer'
import { getAllChildrenDeep } from 'components/research/research-helpers'

interface Props {
  onClose: () => void
}

export const AssistantTaxKnowledgeSource: React.FC<Props> = () => {
  const { taxonomy, isLoading: isTaxonomyLoading } = useResearchTaxonomyQuery(
    ResearchArea.TAX
  )
  const allFiltersFlattened = useMemo(() => {
    const researchFilters = taxonomy
    return researchFilters.concat(
      researchFilters.flatMap((researchFilter) =>
        getAllChildrenDeep(researchFilter)
      )
    )
  }, [taxonomy])

  const [selectedFilters, setSelectedFilters] = useState<ResearchFilter[]>([])
  const [knowledgeSource, setKnowledgeSource] = useAssistantStore(
    useShallow((s) => [s.knowledgeSource, s.setKnowledgeSource])
  )

  useMount(() => {
    if (isTaxKnowledgeSource(knowledgeSource)) {
      const restrictsSet = new Set(knowledgeSource.restricts)
      const preselectedFilters = allFiltersFlattened.filter((filter) =>
        restrictsSet.has(filter.id)
      )
      if (preselectedFilters.length > 0) {
        setSelectedFilters(preselectedFilters)
      }
    }
  })

  const onSetSelectedFilters = (filters: ResearchFilter[]) => {
    setSelectedFilters(filters)

    if (filters.length > 0) {
      // corpus id is the first part of the filter id
      const corpusId = filters[0].id.split('.')[0]

      if (!corpusId) return

      setKnowledgeSource({
        type: DatabaseSource.TAX,
        corpusId,
        restricts: filters.map((f) => f.id),
      })
    }
  }

  return (
    <Explorer
      isOpen={true}
      taxonomy={taxonomy as ResearchFilter[]}
      selectedFilters={selectedFilters}
      setSelectedFilters={onSetSelectedFilters}
      isLoading={isTaxonomyLoading}
      researchArea={ResearchArea.TAX}
    />
  )
}
