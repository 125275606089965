import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useUnmount } from 'react-use'

import Assistant from 'components/assistant/assistant-page'
import { useAuthUser } from 'components/common/auth-context'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'

import { useAssistantChatStreamHandler } from './hooks/use-assistant-chat'
import { useAssistantDraftStreamHandler } from './hooks/use-assistant-draft'
import AssistantHome from './pages/assistant-home-page'
import AssistantModeSwitcher from './pages/assistant-mode-switcher'
import { AssistantSharedIndex } from './pages/assistant-shared-page'
import AssistantV1Redirect from './pages/assistant-v1-redirect'

const AssistantIndex = () => {
  const userInfo = useAuthUser()
  const useChat = useAssistantChatStreamHandler()
  const useDraft = useAssistantDraftStreamHandler()

  useUnmount(() => {
    useChat.sendCancelRequest()
    useDraft.sendCancelRequest()
  })

  if (!userInfo.IsAssistantV2User) {
    return (
      <Routes>
        <Route path="/:id?" element={<Assistant />} />
        <Route
          path=":mode/:eventId/:messageId?"
          element={<ErrorPage title={ErrorPageTitle.NOT_AUTHORIZED} />}
        />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<AssistantHome useChat={useChat} useDraft={useDraft} />}
      />
      <Route
        path=":mode/:eventId/:messageId?"
        element={
          <AssistantModeSwitcher useChat={useChat} useDraft={useDraft} />
        }
      />
      <Route path=":id" element={<AssistantV1Redirect />} />
      <Route path="/shared" element={<AssistantSharedIndex />} />
    </Routes>
  )
}

export default AssistantIndex
