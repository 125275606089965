import React, { useMemo } from 'react'

import { useSharingStore } from 'stores/sharing-store'
import {
  PermissionLevel,
  PermissionsByUser,
  SharePopoverScreens,
  ShareType,
} from 'types/sharing'

import { Badge } from 'components/ui/badge'
import { TagInput } from 'components/ui/tag-input/tag-input'
import { useVaultStore } from 'components/vault/utils/vault-store'

import PermissionLevelDropdown from './permission-level-dropdown'

const SharingUserInput = ({
  inputExplanationText,
  permissionsByUser,
  selectedUserIdsForShare,
  selectedPermissionLevelForShare,
  setSelectedUserIdsForShare,
  setSelectedPermissionLevelForShare,
  shareType,
}: {
  inputExplanationText: string
  permissionsByUser: PermissionsByUser
  selectedUserIdsForShare: string[]
  selectedPermissionLevelForShare: PermissionLevel
  setSelectedUserIdsForShare: (userIds: string[]) => void
  setSelectedPermissionLevelForShare: (permissionLevel: PermissionLevel) => void
  shareType: ShareType
}) => {
  const currentScreen = useSharingStore((state) => state.currentScreen)
  const sharingUsersForWorkspace = useSharingStore(
    (state) => state.sharingUsersForWorkspace
  )
  const currentProject = useVaultStore((state) => state.currentProject)

  // TODO: can pass in shareType prop and set resourceOwner based on that
  const resourceOwner = currentProject?.userId

  const setCurrentScreen = useSharingStore((state) => state.setCurrentScreen)

  const filteredAndSortedSharingUsersForWorkspace = useMemo(() => {
    const alreadySharedUsers = new Set(
      permissionsByUser.map((userPermission) => userPermission.userId)
    )
    return sharingUsersForWorkspace
      .filter(
        (sharingUser) =>
          !alreadySharedUsers.has(sharingUser.userId) &&
          sharingUser.userId !== resourceOwner
      )
      .sort((a, b) => a.userEmail.localeCompare(b.userEmail))
  }, [sharingUsersForWorkspace, permissionsByUser, resourceOwner])

  return (
    <div className="flex flex-col" data-testid="sharing-user-input">
      <div className="mb-2 flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          <TagInput
            placeholder="Enter email address"
            sortedTags={filteredAndSortedSharingUsersForWorkspace.map(
              (sharingUser) => ({
                badgeDisplayText: sharingUser.userEmail,
                value: sharingUser.userId,
                component: (
                  <SharingUserListItem userEmail={sharingUser.userEmail} />
                ),
              })
            )}
            selectedTagValues={selectedUserIdsForShare}
            setSelectedTagValues={setSelectedUserIdsForShare}
            onFocus={(e) => {
              if (currentScreen === SharePopoverScreens.SHARE) {
                e.preventDefault()
                setCurrentScreen(SharePopoverScreens.GRANT_USER_PERMISSION)
              }
            }}
          />
          <div className="text-xs text-muted">{inputExplanationText}</div>
        </div>
        {currentScreen === SharePopoverScreens.GRANT_USER_PERMISSION && (
          <PermissionLevelDropdown
            currentPermissionLevel={selectedPermissionLevelForShare}
            showRemoveOption={false}
            onPermissionLevelChange={setSelectedPermissionLevelForShare}
            shareType={shareType}
          />
        )}
      </div>
    </div>
  )
}

const SharingUserListItem = ({ userEmail }: { userEmail: string }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-x-2">
        <Badge variant="secondary" isPill className="h-6 w-6 rounded-full px-0">
          <span className="w-6 text-center text-xs">
            {userEmail[0].toUpperCase()}
          </span>
        </Badge>
        <p className="line-clamp-2">{userEmail}</p>
      </div>
    </div>
  )
}

export default SharingUserInput
