import { useCallback, useEffect, useMemo } from 'react'

import { isEqual } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useBatchPaginatedQuery } from 'models/queries/lib/use-paginated-query'

import { FetchLibraryItems } from './library-fetcher'
import {
  createLibraryItemStore,
  useExampleItemsStore,
  usePromptItemsStore,
} from './library-items-store'
import { LibraryItemKind } from './library-types'

interface RequestOptions {
  isEnabled?: boolean
}

const DEFAULT_BATCH_SIZE = 25

export const useLibraryData = (
  type: LibraryItemKind,
  options?: RequestOptions
) => {
  const { isEnabled = true } = options || {}
  const itemStore = useItemStore(type) as ReturnType<
    typeof createLibraryItemStore
  >

  const [storeItems, isLoading, lastUpdatedTime] = itemStore(
    useShallow((s) => [s.items, s.isLoading, s.lastUpdatedTime])
  )
  const libraryItems = useMemo(() => Object.values(storeItems), [storeItems])

  const [setIsLoading, setItems, updateLastUpdatedTime] = itemStore(
    useShallow((s) => [s.setIsLoading, s.setItems, s.updateLastUpdatedTime])
  )

  const {
    items,
    isLoading: isBatchLoading,
    refetch,
  } = useBatchPaginatedQuery({
    queryKey: [HarvQueryKeyPrefix.LibraryItemQuery, type],
    queryFn: (pageParams) => FetchLibraryItems(type, pageParams),
    createdKey: 'created',
    batchSize: DEFAULT_BATCH_SIZE,
    isEnabled,
  })

  useEffect(() => {
    const sortedItemIds = items.map((item) => item.id).sort()
    const sortedLibraryItemItems = libraryItems.map((item) => item.id).sort()

    const itemsChanged = !isEqual(sortedItemIds, sortedLibraryItemItems)
    const loadingChanged = isBatchLoading !== isLoading

    if (itemsChanged || loadingChanged) {
      if (itemsChanged) {
        setItems(items)
      }
      if (loadingChanged) {
        setIsLoading(isBatchLoading)
      }
      updateLastUpdatedTime()
    }
  }, [
    items,
    isBatchLoading,
    isLoading,
    libraryItems,
    setIsLoading,
    setItems,
    updateLastUpdatedTime,
  ])

  const refetchItems = useCallback(() => refetch(), [refetch])

  return {
    items: storeItems,
    isLoading,
    lastUpdatedTime,
    refetchItems,
  }
}

const useItemStore = (type: LibraryItemKind) => {
  switch (type) {
    case LibraryItemKind.EXAMPLE:
      return useExampleItemsStore
    case LibraryItemKind.PROMPT:
      return usePromptItemsStore
    default:
      throw new Error('Invalid LibraryItemKind')
  }
}
