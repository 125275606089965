import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'

import { Source } from 'utils/task'
import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'

import { FeedbackResult } from 'components/common/feedback'

interface State {
  activeDocument: Maybe<UploadedFile>
  feedback: FeedbackResult | null
  isLoading: boolean
  response: string
  sources: Source[]
  themes: string[]
  userProvidedContext: string
  userProvidedStyle: string
}

interface Action {
  reset: () => void
  setActiveDocument: (document: UploadedFile | null) => void
  setFeedback: (feedback: FeedbackResult | null) => void
  setTask: HarveySocketSetter
  setThemes: (themes: string[]) => void
  setUserProvidedContext: (userProvidedContext: string) => void
  setUserProvidedStyle: (userProvidedStyle: string) => void
}

const initialState: State = {
  activeDocument: null,
  feedback: null,
  isLoading: false,
  response: '',
  sources: [],
  themes: [],
  userProvidedContext: '',
  userProvidedStyle: '',
}

const useDiligenceTranscriptsStore = create(
  devtools(
    immer<State & Action>((set) => ({
      ...initialState,
      reset: () => {
        set(() => ({
          ...initialState,
        }))
      },
      setActiveDocument: (document: UploadedFile | null) =>
        set({ activeDocument: document }),
      setFeedback: (feedback: FeedbackResult | null) =>
        set({ feedback: feedback }),
      setTask: (socketState: Partial<HarveySocketTask>) => {
        set(() => ({
          ...socketState,
        }))
      },
      setThemes: (themes: string[]) => set({ themes: themes }),
      setUserProvidedContext: (userProvidedContext: string) =>
        set({ userProvidedContext: userProvidedContext }),
      setUserProvidedStyle: (userProvidedStyle: string) =>
        set({ userProvidedStyle: userProvidedStyle }),
    }))
  )
)

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('diligence-transcripts-store', useDiligenceTranscriptsStore)
}

export default useDiligenceTranscriptsStore
