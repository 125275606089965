import React from 'react'

import Services from 'services'

import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { StarredRecord } from 'components/filter/types/starred-record'
import { Switch } from 'components/ui/switch'

const StarredFilter = (props: {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  isStarred?: (record: StarredRecord) => boolean
}) => {
  const { trackEvent } = useAnalytics()
  const { filterKey, value, setValue } = props
  const onCheckedChange = (checked: boolean) => {
    const newValue = checked ? 'true' : 'false'
    if (value !== newValue) {
      setValue(checked ? 'true' : '')
      Services.HoneyComb.Record({
        metric: 'ui.library_starred_filter_change',
        favorite: checked,
      })
      trackEvent(FILTER_CHANGED_EVENT_NAME, {
        entity_name: 'library table',
        field_changed: 'starred',
        starred: checked,
      })
    }
  }

  const render: React.FC = () => {
    return (
      <Switch
        defaultChecked={false}
        key={filterKey}
        checked={value === 'true'}
        onCheckedChange={onCheckedChange}
      />
    )
  }

  const filterLogic = (record: StarredRecord) =>
    value !== 'true' || !!props.isStarred?.(record)

  return { filterKey, displayName: 'Starred', render, filterLogic }
}

export default StarredFilter
