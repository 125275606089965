import React, { useEffect, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  DatabaseSource,
  FileSource,
  KnowledgeSource,
  KnowledgeSourceConfig,
} from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'

import AssistantFilesInput from './assistant-files-input'
import { AssistantMemosKnowledgeSource } from './assistant-memos-knowledge-source'
import { AssistantMode } from './assistant-mode-select'
import { AssistantTaxKnowledgeSource } from './assistant-tax-knowledge-source'
import AssistantVaultKnowledgeSource from './assistant-vault-knowledge-source'

interface Props {
  knowledgeSources: Set<KnowledgeSource>
  isForkingFiles: boolean // When user chooses 'Reuse documents' from 'New thread' dropdown in a thread header
  mode: AssistantMode
  setFilesAskHarveyDisabled: (disabled: boolean) => void
}
const AssistantKnowledgeSourceInput = ({
  knowledgeSources,
  isForkingFiles,
  mode,
  setFilesAskHarveyDisabled,
}: Props) => {
  const [documents, documentsUploading, knowledgeSource, setKnowledgeSource] =
    useAssistantStore(
      useShallow((s) => [
        s.documents,
        s.documentsUploading,
        s.knowledgeSource,
        s.setKnowledgeSource,
      ])
    )

  const isFileSource =
    isForkingFiles || documents.length > 0 || documentsUploading.length > 0

  const defaultSource = isFileSource
    ? FileSource.FILES
    : knowledgeSource
    ? knowledgeSource.type
    : undefined

  const [activeSource, setActiveSource] = useState<KnowledgeSource | undefined>(
    defaultSource
  )
  const sourceConfig = activeSource
    ? KnowledgeSourceConfig[activeSource]
    : undefined

  useEffect(() => {
    setActiveSource(() => {
      const newSource = isFileSource
        ? FileSource.FILES
        : knowledgeSource
        ? knowledgeSource.type
        : undefined
      if (newSource && knowledgeSources.has(newSource)) return newSource
      return undefined
    })
  }, [isFileSource, knowledgeSource, knowledgeSources])

  const databaseSources = Object.values(DatabaseSource).filter((source) =>
    knowledgeSources.has(source)
  )
  const fileSources = Object.values(FileSource).filter((source) =>
    knowledgeSources.has(source)
  )
  const internalFileSources = fileSources.filter(
    (source) => source !== FileSource.FILES
  )

  const hasDatabases = databaseSources.length > 0
  const hasFiles = fileSources.length > 0

  const showDatabases = hasDatabases && !activeSource
  const showFiles =
    hasFiles && (!activeSource || activeSource === FileSource.FILES)

  const handleFileUpload = () => setActiveSource(FileSource.FILES)
  const handleSetSource = (
    source: Exclude<KnowledgeSource, FileSource.FILES>
  ) => {
    setActiveSource(source)
    setKnowledgeSource({ type: source })
  }
  const handleSourceCancel = () => {
    setActiveSource(undefined)
    setKnowledgeSource(null)
  }

  const dropzoneSources = internalFileSources.map((source) => {
    const config = KnowledgeSourceConfig[source]
    if (!config) return null
    return (
      <Button
        key={source}
        className="text-primary"
        onClick={(e) => {
          e.stopPropagation()
          setActiveSource(source)
        }}
        size="sm"
        variant="outline"
      >
        {React.cloneElement(config.icon, {
          className: 'h-4 w-4 shrink-0 mr-2',
        })}
        {config.label}
      </Button>
    )
  })

  return (
    <div
      className={cn(
        // We fix the height so when user moves on to files uploaded view,
        // or knowledge source view, the area doesn't jump.
        'flex h-64 flex-col rounded-md rounded-t-none bg-secondary p-4',
        {
          'border border-t-0 bg-primary p-0 shadow-sm': !!activeSource,
          // Handle extra height of 'Files' and 'Databases' header,
          // or height of file source buttons
          'h-72': hasFiles && (hasDatabases || internalFileSources.length > 0),
          // Handle both header and source buttons
          'h-80': hasFiles && hasDatabases && internalFileSources.length > 0,
        }
      )}
    >
      {!activeSource && <p className="my-2 font-medium">Sources</p>}
      <div className="flex min-h-0 grow gap-4">
        {showFiles && (
          <div className="grow">
            {isForkingFiles ? (
              <div className="px-6 py-4">
                <p className="font-medium">Files</p>
                <p className="flex items-center px-1 py-2 text-xs">
                  <Spinner className="mr-2 h-4 w-4" />
                  Duplicating documents
                </p>
              </div>
            ) : (
              <AssistantFilesInput
                dropzoneSources={dropzoneSources}
                hasFilesHeader={hasDatabases && !activeSource}
                mode={mode}
                onCancel={handleSourceCancel}
                onUpload={handleFileUpload}
                setAskHarveyDisabled={setFilesAskHarveyDisabled}
              />
            )}
          </div>
        )}
        {showDatabases && (
          <div className="w-64">
            {hasFiles && (
              <p className="pb-2 text-xs font-medium text-secondary">
                Knowledge
              </p>
            )}
            <div className="space-y-2">
              {databaseSources.map((source) => {
                const config = KnowledgeSourceConfig[source]
                if (!config) return null
                return (
                  <Button
                    key={source}
                    className="flex h-auto w-full items-center justify-start border px-4 py-3 hover:bg-secondary-hover"
                    onClick={() => handleSetSource(source)}
                    variant="unstyled"
                  >
                    {React.cloneElement(config.icon, {
                      className: 'h-4 w-4 shrink-0 mr-2',
                    })}
                    {config.label}
                  </Button>
                )
              })}
            </div>
          </div>
        )}
        {/* TODO: Create a map for these */}
        {activeSource === FileSource.VAULT && (
          <AssistantVaultKnowledgeSource onClose={handleSourceCancel} />
        )}
        {activeSource === DatabaseSource.MEMOS && (
          <AssistantMemosKnowledgeSource onClose={handleSourceCancel} />
        )}
        {activeSource === DatabaseSource.TAX && (
          <AssistantTaxKnowledgeSource onClose={handleSourceCancel} />
        )}
      </div>
      {sourceConfig && !sourceConfig.hideFooter && (
        <div className="flex items-center justify-between border-t px-4 py-3">
          <div className="flex items-center text-xs">
            {React.cloneElement(sourceConfig.icon, {
              className: 'h-4 w-4 shrink-0 mr-2',
            })}
            {sourceConfig.name}
          </div>
          <Button size="sm" variant="outline" onClick={handleSourceCancel}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  )
}

export default AssistantKnowledgeSourceInput
