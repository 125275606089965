/* tslint:disable */
/* eslint-disable */
/**
 * Vault API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VaultWorkflowVisibilityKind = {
    DRAFT: 'DRAFT',
    WORKSPACE: 'WORKSPACE',
    PUBLIC: 'PUBLIC'
} as const;
export type VaultWorkflowVisibilityKind = typeof VaultWorkflowVisibilityKind[keyof typeof VaultWorkflowVisibilityKind];


export function instanceOfVaultWorkflowVisibilityKind(value: any): boolean {
    for (const key in VaultWorkflowVisibilityKind) {
        if (Object.prototype.hasOwnProperty.call(VaultWorkflowVisibilityKind, key)) {
            if (VaultWorkflowVisibilityKind[key as keyof typeof VaultWorkflowVisibilityKind] === value) {
                return true;
            }
        }
    }
    return false;
}

export function VaultWorkflowVisibilityKindFromJSON(json: any): VaultWorkflowVisibilityKind {
    return VaultWorkflowVisibilityKindFromJSONTyped(json, false);
}

export function VaultWorkflowVisibilityKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): VaultWorkflowVisibilityKind {
    return json as VaultWorkflowVisibilityKind;
}

export function VaultWorkflowVisibilityKindToJSON(value?: VaultWorkflowVisibilityKind | null): any {
    return value as any;
}

