import React, { useRef, useState } from 'react'

import { Packer } from 'docx'
import { saveAs } from 'file-saver'
import { Maximize2, Minimize2 } from 'lucide-react'
import * as XLSX from 'xlsx'

import { FileType } from 'types/file'

import { htmlToDocx } from 'utils/docx'
import { backendFormat, s2ab } from 'utils/utils'

import ExportDialog from 'components/common/export/export-dialog'
import {
  ExportOptionGroup,
  ExportOptionValues,
} from 'components/common/export/types'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'

interface Props {
  children: React.ReactNode
  width?: string
}
const Table = ({ children, width }: Props) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const handleExpand = () => setIsExpanded(true)
  const handleClose = () => setIsExpanded(false)
  const handleOpenChange = (isOpen: boolean) => {
    setIsExpanded(isOpen)
  }

  const exportOptions: ExportOptionGroup[] = [
    {
      id: 'format',
      name: 'Format',
      options: [
        {
          label: 'Microsoft Word',
          value: FileType.WORD,
        },
        {
          label: 'Microsoft Excel',
          value: FileType.EXCEL,
        },
        {
          label: 'CSV',
          value: FileType.CSV,
        },
      ],
      type: 'radio',
      defaultValue: FileType.WORD,
    },
  ]

  const handleExport = async (exportValues: ExportOptionValues) => {
    const tableHtml = tableRef.current?.outerHTML ?? ''
    // Remove rendered Citation components through Markdown.
    // TODO: Maybe allow exporting with citations, but there's no clean way to
    // format the references for CSV/XLSX exports
    const cleanedHtml = tableHtml
      .replace(/<a\s+[^>]*data-state="closed"[^>]*>.*?<\/a>/g, '')
      .replace(/<button\s+[^>]*class="source[^>]*>.*?<\/button>/g, '')

    const dateTime = backendFormat(new Date())
    const fileName = `Table_${dateTime}`
    const format = exportValues.format
    switch (format) {
      case FileType.WORD: {
        const doc = await htmlToDocx({
          html: cleanedHtml,
          injectTitle: null,
          sources: null,
        })
        const blob = await Packer.toBlob(doc)
        return saveAs(blob, `${fileName}.docx`)
      }

      case FileType.EXCEL:
      case FileType.CSV: {
        // Write cleaned table back into an element for XLSX to process
        const tempContainer = document.createElement('div')
        tempContainer.innerHTML = cleanedHtml

        const xlsxTable = tempContainer.querySelector('table')
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.table_to_sheet(xlsxTable)
        XLSX.utils.book_append_sheet(wb, ws, 'Table')

        if (format === FileType.EXCEL) {
          const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
          return saveAs(
            new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
            `${fileName}.xlsx`
          )
        } else {
          const csvout = XLSX.write(wb, { bookType: 'csv', type: 'string' })
          return saveAs(
            new Blob([csvout], { type: 'text/csv' }),
            `${fileName}.csv`
          )
        }
      }

      default:
        console.error('Unsupported export type')
        return
    }
  }

  return (
    <div
      className="-mx-4 rounded-md bg-accent p-1"
      style={{ width: `calc(${width ?? '100%'} + 32px)` }}
    >
      <div className="flex items-center justify-between px-1 pb-1">
        <p className="not-prose px-2 pt-1 text-xs font-medium text-secondary">
          Table
        </p>

        <Button
          className="text-muted hover:bg-button-secondary-hover hover:text-muted"
          onClick={handleExpand}
          size="xsIcon"
          variant="ghost"
        >
          <Icon icon={Maximize2} size="small" />
        </Button>
      </div>
      <ScrollArea className="rounded-sm border bg-primary" hasHorizontalScroll>
        <table className="[&_td:first-child]:pl-4 [&_td:last-child]:pr-4 [&_th:first-child]:pl-4 [&_th:last-child]:pr-4">
          {children}
        </table>
      </ScrollArea>
      <Dialog open={isExpanded} onOpenChange={handleOpenChange}>
        <DialogContent
          className="h-full max-h-[calc(100vh-64px)] w-full max-w-[calc(100vw-64px)]"
          innerClassName="p-0"
          onOpenAutoFocus={(e) => e.preventDefault()}
          showCloseIcon={false}
        >
          <div className="flex shrink-0 items-center justify-between border-b py-3">
            <div className="px-8 text-xl font-medium">Table</div>
            <div className="flex items-center space-x-4">
              <ExportDialog
                disabled={false}
                optionGroups={exportOptions}
                onExport={handleExport}
                title="Export table"
              />
              <Button
                className="text-secondary hover:text-primary"
                onClick={handleClose}
                variant="unstyled"
                size="smIcon"
              >
                <Icon icon={Minimize2} />
              </Button>
            </div>
          </div>
          <div className="virtualized-scrollbar prose prose-harvey w-full max-w-none grow pb-4">
            <table
              className="[&_td:first-child]:pl-8 [&_td:last-child]:pr-8 [&_td]:p-2.5 [&_th:first-child]:pl-8 [&_th:last-child]:pr-8 [&_th]:p-2.5"
              ref={tableRef}
            >
              {children}
            </table>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Table
