import React from 'react'

import _ from 'lodash'

import { UserInfo } from 'models/user-info'
import { EventKind } from 'openapi/models/EventKind'
import Services from 'services'
import { WorkflowTypeToTaskType } from 'types/workflows'

import { groupTaskTypeEntries } from 'utils/task-definitions'
import { cn } from 'utils/utils'

import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { TaskTypeRecord } from 'components/filter/types/task-type-record'
import { MultiSelect, MultiSelectEntry } from 'components/ui/multi-select'

const TaskTypeFilter = (props: {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  userInfo?: UserInfo
  taskLabelLookup?: Record<string, string>
  sortedTaskTypes?: MultiSelectEntry[]
  entityName: string
}) => {
  const { trackEvent } = useAnalytics()
  const { filterKey, setValue, entityName } = props
  const selectedTaskTypes = props.value?.split(',') || []
  const taskLabelLookup = props.taskLabelLookup || {}
  const selectedTaskTypeLabels = new Set(
    selectedTaskTypes.map((type) => taskLabelLookup[type])
  )

  const { sortedTaskTypes, sortedGroups } = groupTaskTypeEntries(
    props.sortedTaskTypes || [],
    props.userInfo
  )

  const workflowTypes = new Set(Object.values(WorkflowTypeToTaskType))
  const hasWorkflows = sortedTaskTypes.some((entry) =>
    workflowTypes.has(entry.value as EventKind)
  )
  const hasResearch = sortedTaskTypes.some(
    (entry) => !workflowTypes.has(entry.value as EventKind)
  )
  const ungroupedTitle = !hasWorkflows
    ? 'Research'
    : !hasResearch
    ? 'Workflows'
    : 'Research & Workflows'

  const render: React.FC = () => {
    return (
      <MultiSelect
        key={filterKey}
        placeholder="Select"
        sortedEntries={sortedTaskTypes}
        sortedGroups={sortedGroups}
        ungroupedTitle={ungroupedTitle}
        selectedValues={selectedTaskTypes}
        setSelectedValues={(selectedValues) => {
          setValue(selectedValues.join(','))
          Services.HoneyComb.Record({
            metric: 'ui.history_task_type_filter_change',
            task_types: selectedValues.join(','),
          })
          trackEvent(FILTER_CHANGED_EVENT_NAME, {
            entity_name: entityName || 'generic task type filter',
            field_changed: 'task type',
            task_types: selectedValues.join(','),
          })
        }}
        className={cn('w-40', {
          'text-muted': _.isEmpty(selectedTaskTypes),
        })}
        align="end"
      />
    )
  }

  const filterLogic = (record: TaskTypeRecord) => {
    return (
      selectedTaskTypes.length === 0 ||
      selectedTaskTypeLabels.has(taskLabelLookup[record.kind.toString()])
    )
  }

  return { filterKey, displayName: 'Type', render, filterLogic }
}

export default TaskTypeFilter
