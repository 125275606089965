import React from 'react'
import { Routes, Route, useParams, useNavigate } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { useSharingStore } from 'stores/sharing-store'

import { BaseAppPath } from 'components/base-app-path'
import { CLIENT_MATTER_URL_PARAM } from 'components/client-matters/client-matter-utils'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import RedirectWithQuery from 'components/common/redirects/redirect-with-query'
import GeneralStoreListener from 'components/general-store-listener'

import useVaultUploadFiles from './hooks/use-vault-upload-files'
import { useVaultFolderUpdatesSubscription } from './utils/use-vault-folder-updates-subscription'
import {
  GenerateN1ResponseProps,
  GenerateNNResponseProps,
  GenerateQuestionsProps,
  QueryQuestionsResponseData,
  projectsPath,
  queriesPath,
  filesPath,
} from './utils/vault'
import { useVaultCreateProjectStore } from './utils/vault-create-project-store'
import {
  FetchVaultFolderShareStatus,
  SetVaultFolderLastOpened,
} from './utils/vault-fetcher'
import { useVaultFileExplorerStore } from './utils/vault-file-explorer-store'
import { useVaultSharingStore } from './utils/vault-sharing-store'
import { useVaultStore } from './utils/vault-store'
import VaultFilePreviewer from './vault-file-previewer'
import VaultProjectDetail from './vault-project-detail'
import VaultProjectQueries from './vault-project-queries'
import VaultQueryDetail from './vault-query-detail'

const VaultProjectLayout = ({
  generateQuestions,
  generateNNResponse,
  generateN1Response,
  sendCancelRequestN1,
}: {
  generateQuestions: (
    props: GenerateQuestionsProps
  ) => Promise<QueryQuestionsResponseData>
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  generateN1Response: (props: GenerateN1ResponseProps) => Promise<void>
  sendCancelRequestN1: () => void
}) => {
  useVaultUploadFiles()
  const { projectId } = useParams()
  const userInfo = useAuthUser()
  const navigate = useNavigate()
  const projectsMetadata = useVaultStore(useShallow((s) => s.projectsMetadata))
  const folderIdToVaultFolder = useVaultStore(
    useShallow((s) => s.folderIdToVaultFolder)
  )
  const [
    setIsProjectLayoutLoading,
    setCurrentProject,
    setAreUploadButtonsDisabled,
    upsertVaultFolders,
  ] = useVaultStore(
    useShallow((s) => [
      s.setIsProjectLayoutLoading,
      s.setCurrentProject,
      s.setAreUploadButtonsDisabled,
      s.upsertVaultFolders,
    ])
  )

  const clearFilesToUpload = useVaultCreateProjectStore(
    (s) => s.clearFilesToUpload
  )

  const setPermissionsForProjectId = useVaultSharingStore(
    (s) => s.setPermissionsForProjectId
  )
  const setIsFetchingFolderShareStatus = useVaultSharingStore(
    (s) => s.setIsFetchingFolderShareStatus
  )
  const setDidFetchFolderShareStatusFail = useSharingStore(
    (s) => s.setDidFetchSharingStatusFail
  )
  const clearSearchHandler = useVaultFileExplorerStore(
    (s) => s.clearSearchHandler
  )

  const [
    clientMatters,
    setSelectedClientMatter,
    setClientMatterSelectDisabled,
  ] = useClientMattersStore(
    useShallow((s) => [
      s.clientMatters,
      s.setSelectedClientMatter,
      s.setClientMatterSelectDisabled,
    ])
  )

  // we need to add this useMount to update the current project
  // because the current project is cleared when the component is unmounted
  // this will ensure that the header and other components have the correct project
  useMount(async () => {
    if (!projectId) return
    setIsProjectLayoutLoading(true)

    const project = folderIdToVaultFolder[projectId]
    if (project) {
      setCurrentProject(project)
    }
    if (userInfo.IsVaultViewSharesUser) {
      try {
        setIsFetchingFolderShareStatus(true)
        const response = await FetchVaultFolderShareStatus(projectId)
        setDidFetchFolderShareStatusFail(false)
        setPermissionsForProjectId({
          projectId,
          userId: userInfo.dbId,
          workspaceId: userInfo.workspace.id,
          permissions: response.shareStatus,
        })
      } catch (error) {
        setDidFetchFolderShareStatusFail(true)
        console.error('Error fetching vault folder share status')
      }
      setIsFetchingFolderShareStatus(false)
    }
    setIsProjectLayoutLoading(false)

    if (userInfo.IsVaultProjectClientMatterUser) {
      // set current client matter if exists on project
      const clientMatter = clientMatters.find(
        (cm) => cm.id === project?.clientMatterId
      )
      setSelectedClientMatter(clientMatter)
      if (clientMatter && !_.isEmpty(clientMatter.name)) {
        // Update the URL with the selected client matter only if there are client matters
        const searchParams = new URLSearchParams(location.search)
        searchParams.set(CLIENT_MATTER_URL_PARAM, clientMatter.name)
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        })
        setClientMatterSelectDisabled(true)
      }
    }

    // update last opened time for the project
    try {
      void SetVaultFolderLastOpened(projectId)
      if (project) {
        upsertVaultFolders(
          [{ ...project, lastOpenedAt: new Date().toISOString() }],
          userInfo.dbId,
          false
        )
      }
    } catch (error) {
      console.error('Error updating last opened time for the project')
    }
  })

  useVaultFolderUpdatesSubscription(projectId!)

  useUnmount(() => {
    setAreUploadButtonsDisabled(true)
    clearFilesToUpload()
    setCurrentProject(null)
    setIsProjectLayoutLoading(false)
    clearSearchHandler()
    if (userInfo.IsVaultProjectClientMatterUser) {
      setClientMatterSelectDisabled(false)
    }
  })

  if (!projectId) return null

  if (!projectsMetadata[projectId]) {
    return (
      <AppMain className="flex w-full">
        <FullscreenLoading isLoading zIndex="z-50" />
      </AppMain>
    )
  }

  // if the user navigates to /files, let's redirect them to the project page
  return (
    <>
      <GeneralStoreListener />
      <Routes>
        <Route
          path="/"
          element={
            <VaultProjectDetail
              generateQuestions={generateQuestions}
              generateNNResponse={generateNNResponse}
              generateN1Response={generateN1Response}
            />
          }
        />
        <Route
          path={filesPath}
          element={
            <RedirectWithQuery
              to={`${BaseAppPath.Vault}${projectsPath}${projectId}`}
            />
          }
        />
        <Route path={`${filesPath}:fileId`} element={<VaultFilePreviewer />} />
        <Route path={queriesPath} element={<VaultProjectQueries />} />
        <Route
          path={`${queriesPath}:queryId`}
          element={
            <VaultQueryDetail
              sendCancelRequestN1={sendCancelRequestN1}
              generateQuestions={generateQuestions}
              generateNNResponse={generateNNResponse}
              generateN1Response={generateN1Response}
            />
          }
        />
      </Routes>
    </>
  )
}

export default VaultProjectLayout
