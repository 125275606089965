import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { isTaxKnowledgeSource } from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'

export const useIsPwcTax = () => {
  const userInfo = useAuthUser()
  const knowledgeSource = useAssistantStore((s) => s.knowledgeSource)

  const isInternalOrPwcUser = userInfo.IsInternalUser || userInfo.IsPwcUser

  return isTaxKnowledgeSource(knowledgeSource) && isInternalOrPwcUser
}
