import React from 'react'
import { useParams } from 'react-router-dom'

import { capitalize } from 'lodash'
import pluralize from 'pluralize'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { TodayOption, readableFormat } from 'utils/date-utils'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { AssistantMessage } from 'components/assistant-v2/types'
import { KnowledgeSource } from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { SkeletonBlock } from 'components/ui/skeleton'

import { AssistantMode } from './assistant-mode-select'

const DotSeparator = () => <span className="text-xs">・</span>

interface Props {
  isLoading?: boolean
  messages: AssistantMessage[]
  documents: UploadedFile[]
  knowledgeSource?: KnowledgeSource
  sourceLabel?: string
}

const AssistantHeaderSubtitle = ({
  isLoading,
  messages,
  documents,
  sourceLabel,
}: Props) => {
  const { mode } = useParams()
  const createdAt = useAssistantStore((s) => s.createdAt)
  const numFollowups = messages.length - 1
  const numDocs = documents.length
  const followUpNoun = mode === AssistantMode.DRAFT ? 'revision' : 'follow-up'

  if (isLoading)
    return (
      <div className="flex h-4 items-center">
        <SkeletonBlock className="h-3 w-10" />
      </div>
    )

  return (
    <div className="flex h-4 items-center gap-0.5 text-xs text-muted">
      {capitalize(mode)}
      {numFollowups > 0 && (
        <>
          <DotSeparator />
          {numFollowups} {pluralize(followUpNoun, numFollowups)}
        </>
      )}
      {sourceLabel && (
        <>
          <DotSeparator />
          {sourceLabel}
        </>
      )}
      {numDocs > 0 && (
        <>
          <DotSeparator />
          {numDocs} {pluralize('source', numDocs)}
        </>
      )}
      {createdAt && (
        <>
          <DotSeparator />
          Created {readableFormat(createdAt, TodayOption.showDate, 'long')}
        </>
      )}
    </div>
  )
}

export default AssistantHeaderSubtitle
