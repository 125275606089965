import React from 'react'
import { useParams } from 'react-router-dom'

import {
  LucideIcon,
  Plus,
  Scale,
  SquareAsterisk,
  Table2,
  Waypoints,
} from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Card } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import { SkeletonBlock } from 'components/ui/skeleton'
import {
  GenerateNNResponseProps,
  projectsPath,
  queriesPath,
} from 'components/vault/utils/vault'
import { VaultWorkflow } from 'components/vault/utils/vault-fetcher'
import VaultNewWorkflow from 'components/vault/workflows/vault-new-workflow'
import VaultPublishWorkflow from 'components/vault/workflows/vault-publish-workflow'

import { useVaultWorkflows } from './use-vault-workflows'
import VaultWorkflowBuilder from './vault-workflow-builder'
import VaultWorkflowSelector from './vault-workflow-selector'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

const VaultWorkflowList = ({
  generateNNResponse,
}: {
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const { workflows, isFetching } = useVaultWorkflows()
  return (
    <div className="flex gap-3">
      <NewWorkflowCard />
      {isFetching &&
        !workflows &&
        [0, 1, 2].map((i) => <WorkflowCardSkeleton key={i} index={i} />)}
      {workflows &&
        workflows
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((workflow) => ({
            ...workflow,
            icon: SquareAsterisk,
          }))
          .slice(0, 3)
          .map((workflow, index) => (
            <WorkflowCard
              key={workflow.id}
              index={index}
              icon={workflow.icon}
              workflow={workflow}
            />
          ))}
      <AllWorkflowsCard />
      <VaultWorkflowSelector />
      <VaultNewWorkflow />
      <VaultPublishWorkflow />
      <VaultWorkflowBuilder generateNNResponse={generateNNResponse} />
    </div>
  )
}

const WorkflowCard = ({
  icon,
  workflow,
  index,
}: {
  icon: LucideIcon
  workflow: VaultWorkflow
  index: number
}) => {
  const [setWorkflowModalState, setSelectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )
  return (
    <Card
      className={cn(
        'h-28 w-full items-center justify-center rounded border bg-secondary p-4 shadow-sm hover:cursor-pointer hover:border-input-focused',
        {
          'hidden sm:flex': index === 0,
          'hidden lg:flex': index === 1,
          'hidden xl:flex': index === 2,
        }
      )}
      onClick={() => {
        setSelectedWorkflow(workflow)
        setWorkflowModalState(WorkflowModalState.Builder)
      }}
    >
      <div className="flex w-full flex-col items-start gap-2">
        <div className="flex items-center justify-center gap-2">
          <Icon icon={icon} />
          <p className="line-clamp-1 text-sm font-medium">{workflow.name}</p>
        </div>
        <p className="line-clamp-3 text-xs text-muted">
          {workflow.description}
        </p>
      </div>
    </Card>
  )
}

const WorkflowCardSkeleton = ({ index }: { index: number }) => {
  return (
    <Card
      className={cn(
        'h-28 w-full items-center justify-center rounded border bg-secondary p-4 shadow-sm',
        {
          'hidden sm:flex': index === 0,
          'hidden lg:flex': index === 1,
          'hidden xl:flex': index === 2,
        }
      )}
    >
      <div className="flex w-full flex-col items-start gap-2">
        <div className="flex w-full items-center justify-center gap-2">
          <Icon icon={SquareAsterisk} />
          <SkeletonBlock className="h-4 w-full" />
        </div>
        <SkeletonBlock className="h-4 w-full" />
      </div>
    </Card>
  )
}

const NewWorkflowCard = () => {
  const { projectId } = useParams()
  const { trackEvent } = useAnalytics()
  return (
    <Link
      to={`${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}new`}
      onClick={() => {
        trackEvent('Vault blank workflow button clicked', {
          source: 'blank-workflow-card',
        })
      }}
      className="flex h-28 w-28 shrink-0 items-center justify-center rounded border border-dashed p-2.5 shadow-sm hover:cursor-pointer hover:border-input-focused"
    >
      <div className="flex flex-col items-center gap-2">
        <Icon icon={Plus} size="large" className="text-muted" />
        <p className="text-sm font-medium">Blank</p>
      </div>
    </Link>
  )
}

const AllWorkflowsCard = () => {
  const setWorkflowModalState = useVaultWorkflowStore(
    (state) => state.setWorkflowModalState
  )
  return (
    <Card
      className="flex h-28 w-auto shrink-0 items-center justify-center rounded border bg-secondary p-2.5 shadow-sm hover:cursor-pointer hover:border-input-focused"
      onClick={() => setWorkflowModalState(WorkflowModalState.Selector)}
    >
      <div className="grid grid-cols-2 items-center gap-2">
        <WorkflowThumbnail icon={Waypoints} />
        <WorkflowThumbnail icon={Table2} />
        <WorkflowThumbnail icon={Scale} />
        <WorkflowThumbnail text="View All" />
      </div>
    </Card>
  )
}

const WorkflowThumbnail = ({
  icon,
  text,
}: {
  icon?: LucideIcon
  text?: string
}) => {
  return (
    <div className="flex h-10 w-20 items-center justify-center rounded border">
      {icon && <Icon icon={icon} size="large" />}
      {text && <p className="text-xs font-medium">{text}</p>}
    </div>
  )
}

export default VaultWorkflowList
