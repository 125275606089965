import React, { useEffect, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import useState from 'react-usestateref'

import { Button, Radio, type RadioChangeEvent } from 'antd'
import _ from 'lodash'
import { X } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { type Event } from 'models/event'
import { FetchExamples } from 'models/fetchers/examples-fetcher'
import Services from 'services'
import { useExampleStore } from 'stores/example-store'

import { useAvailableTaskLabelLookup } from 'utils/task-definitions'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import FlexiSelect from 'components/common/flexi-select'
import * as Card from 'components/ui/card'
import Link from 'components/ui/link/link'

enum Action {
  NONE = 0,
  SET = 1,
  UNSET = -1,
}

type Props = {
  queryId: string | null
  showSetExample: boolean
  setShowSetExample: (show: boolean) => void
}

const SetExample: React.FC<Props> = ({
  queryId,
  showSetExample,
  setShowSetExample,
}) => {
  const [examples, useCases, documentTypes, setExamples] = useExampleStore(
    useShallow((s) => [s.examples, s.useCases, s.documentTypes, s.setExamples])
  )
  const userInfo = useAuthUser()
  const taskLabelLookup = useAvailableTaskLabelLookup(userInfo)

  useHotkeys(
    'Control+h',
    () => {
      // The below toggles the bit to show the example, *if* create examples permission exists
      // the root permission is checked elsewhere on showExamplePopover variable
      setShowSetExample(!showSetExample)
    },
    [showSetExample]
  )

  const [action, setAction] = useState<Action>(Action.NONE)
  const [example, setExample] = useState<Event | undefined>(undefined)
  const [useCase, setUseCase, useCaseRef] = useState<string>('')
  const [documentType, setDocumentType, documentTypeRef] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)

  const handleAction = (e: RadioChangeEvent): void => {
    setAction(e.target.value)
    if (e.target.value === Action.UNSET) {
      void handleUnset()
    }
  }

  const handleSet = async (): Promise<void> => {
    setSubmitted(true)
    setTimeout(close, 5000)
    await set()
  }

  const handleUnset = async (): Promise<void> => {
    setSubmitted(true)
    setTimeout(close, 5000)
    await unset()
  }

  const set = async (): Promise<void> => {
    if (_.isNil(queryId)) return
    const data = {
      useCase: useCaseRef?.current,
      documentType: documentTypeRef?.current,
    }
    await Services.Backend.Post<any>(`examples/${queryId}`, data, undefined)
    await fetchData()
  }

  const unset = async (): Promise<void> => {
    if (_.isNil(queryId)) return
    await Services.Backend.Delete<any>(`examples/${queryId}`)
    await fetchData()
  }

  const close = (): void => {
    if (_.isNil(queryId)) return
    setShowSetExample(false)
  }

  const fetchData = useCallback(async (): Promise<void> => {
    if (!userInfo.IsExamplesReadUser) return
    const latestExamples = await FetchExamples(taskLabelLookup)
    if (latestExamples.length === 0 && examples.length === 0) {
      return
    }

    setExamples(latestExamples)
  }, [
    examples.length,
    setExamples,
    taskLabelLookup,
    userInfo.IsExamplesReadUser,
  ])

  useEffect(() => {
    const runAsync = async (): Promise<void> => {
      await fetchData()
    }

    if (_.isNil(queryId)) return
    if (examples.length === 0) {
      void runAsync()
      return
    }

    const example = examples.find((e) => e.id === Number(queryId))
    setExample(example)
    setUseCase(example?.useCase ?? '')
    setDocumentType(example?.documentType ?? '')
  }, [queryId, examples, setUseCase, setDocumentType, fetchData])

  useEffect(() => {
    if (_.isNil(queryId)) return
    if (showSetExample) {
      setSubmitted(false)
    }
  }, [queryId, showSetExample])

  if (_.isNil(queryId) || !showSetExample) {
    return <></>
  }

  const shouldShow = userInfo.IsExamplesWriteUser && showSetExample

  if (!shouldShow) return null

  return (
    <div className="relative">
      <Card.Card
        className={cn(
          'absolute inset-0 top-4 z-50 mx-4 bg-primary/70 backdrop-blur transition',
          {
            'h-[40px]': action !== Action.SET,
            'h-fit': action === Action.SET,
          }
        )}
        data-testid="set-example"
      >
        {!submitted && (
          <>
            <div
              className="flex h-full w-full items-center justify-between px-4 py-3"
              data-testid="set-example-header"
            >
              <div className="SetExample-header">
                {!_.isNil(example)
                  ? 'Would you like to edit or delete this example?'
                  : 'Would you like to set this as example?'}
              </div>
              <div className="Feedback-buttons flex">
                {!_.isNil(example) ? (
                  <Radio.Group
                    buttonStyle="solid"
                    size="small"
                    value={action}
                    onChange={handleAction}
                  >
                    <Radio.Button
                      value={Action.SET}
                      data-testid="set-example-edit"
                    >
                      Edit
                    </Radio.Button>
                    <Radio.Button
                      value={Action.UNSET}
                      data-testid="set-example-delete"
                    >
                      Delete
                    </Radio.Button>
                  </Radio.Group>
                ) : (
                  <Radio.Group
                    buttonStyle="solid"
                    size="small"
                    value={action}
                    onChange={handleAction}
                  >
                    <Radio.Button
                      value={Action.SET}
                      data-testid="set-example-yes"
                    >
                      Yes
                    </Radio.Button>
                  </Radio.Group>
                )}

                <button
                  onClick={close}
                  className="ml-2 flex h-6 w-6 items-center justify-center rounded-lg p-1 transition hover:bg-secondary-hover"
                  data-testid="set-example-close"
                >
                  <X />
                </button>
              </div>
            </div>
            {action === Action.SET && (
              <div data-testid="set-example-body">
                <div className="space-y-2 px-4">
                  <p>Practice area</p>
                  <FlexiSelect
                    key="use_case"
                    label="Practice area"
                    value={useCase}
                    options={useCases}
                    onSelect={setUseCase}
                    virtual={false}
                  />

                  <p>Document type</p>
                  <FlexiSelect
                    key="document_type"
                    label="Document type"
                    value={documentType}
                    options={documentTypes}
                    onSelect={setDocumentType}
                    virtual={false}
                  />
                </div>
                <div className="SetExample-content-section px-4 py-3">
                  <Button
                    disabled={_.isNil(useCase)}
                    className="DarkButton"
                    size="small"
                    onClick={() => {
                      void handleSet()
                    }}
                    data-testid="set-example-submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        {submitted && (
          <div className="SetExample-content-section flex h-[40px] items-center px-4 py-3">
            <div className="SetExample-header">
              Thank you for {action === Action.SET ? 'setting' : 'unsetting'}{' '}
              example.{' '}
              <Link
                to={userInfo.IsLibraryUser ? '/library/examples' : '/examples'}
                className="text-primary"
              >
                Click here to view examples
              </Link>
              .
            </div>
          </div>
        )}
      </Card.Card>
    </div>
  )
}

export default SetExample
