import React from 'react'

import _ from 'lodash'

import HarveyLogo from 'components/common/harvey-logo'
import { Button } from 'components/ui/button'

export enum ErrorPageTitle {
  PAGE_NOT_FOUND = 'Page not found',
  SOMETHING_WENT_WRONG = 'Sorry, something went wrong',
  NOT_AUTHORIZED = 'Sorry, you don’t have full access yet',
  INCORRECT_NETWORK = 'Sorry, you’re not on the right network',
}

interface CTA {
  text: string
  onClick: () => void
}

interface Props {
  title?: ErrorPageTitle
  description?: React.ReactNode
  primaryCTA?: CTA
  secondaryCTA?: CTA
}

const DEFAULT_TITLE = ErrorPageTitle.SOMETHING_WENT_WRONG
const DEFAULT_DESCRIPTION = `This may be caused by a number of reasons, including an unstable internet connection.\nPlease check your connection and click “Reload” below to try again.\nContact support@harvey.ai if this issue persists.`
const DEFAULT_CTA_TEXT = 'Reload'
const DEFAULT_CTA_CALLBACK = () => (window.location.pathname = '/')

const Error: React.FC<Props> = ({
  title,
  description,
  primaryCTA,
  secondaryCTA,
}) => {
  const renderDescription = () => {
    if (_.isNil(description)) {
      return DEFAULT_DESCRIPTION.split('\n').map((l, k) => <p key={k}>{l}</p>)
    }
    if (React.isValidElement(description) || Array.isArray(description)) {
      return description
    }
    // At this point, description is likely a string or a number
    return String(description)
      .split('\n')
      .map((l, k) => <p key={k}>{l}</p>)
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center space-y-4">
      <div className="mb-4 h-24 w-24 rounded-lg">
        <div className="flex h-full w-full items-center justify-center rounded bg-accent text-[56px]">
          <HarveyLogo className="h-12 w-12" />
        </div>
      </div>
      <div className="text-center">
        <h1 className="text-xl">{title ?? DEFAULT_TITLE}</h1>
      </div>
      <div className="text-center">
        <div className="text-muted">{renderDescription()}</div>
      </div>
      <div className="flex space-x-2">
        {secondaryCTA && (
          <Button variant="secondary" onClick={secondaryCTA.onClick}>
            {secondaryCTA.text}
          </Button>
        )}
        <Button onClick={primaryCTA?.onClick ?? DEFAULT_CTA_CALLBACK}>
          {primaryCTA?.text ?? DEFAULT_CTA_TEXT}
        </Button>
      </div>
    </div>
  )
}

export default Error
