import * as Sentry from '@sentry/browser'
import { useQueryClient } from '@tanstack/react-query'

import { FetchExamples } from 'models/fetchers/examples-fetcher'
import { getUseCasesDocTypesFromExamples } from 'models/helpers/examples-helper'
import type { ExamplesStore } from 'models/helpers/examples-helper'
import { prefetchWrapper } from 'models/queries/lib'
import Services from 'services'

import { HarvQueryKeyPrefix } from './all-query-keys'
import { useWrappedQuery } from './lib/use-wrapped-query'

// query to fetch examples, note that it takes parameter of taskLabelLookup
const useExamplesQuery = (
  taskLabelLookup: Record<string, any>,
  isEnabled: boolean = true
) => {
  // creating the async function with a stable reference
  const getExamples = async () => await FetchExamples(taskLabelLookup)

  const {
    isPending: isExamplesPending,
    error: examplesError,
    data: _examplesData,
    isFetching: isExamplesFetching,
  } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.ExamplesQuery, taskLabelLookup],
    queryFn: getExamples,
    select: getUseCasesDocTypesFromExamples,
    enabled: isEnabled,
  })

  // Just setting a simple default object to return
  const examplesData =
    _examplesData ||
    ({ examples: [], useCases: [], documentTypes: [] } as ExamplesStore)

  // TODO(Adam): Open question about how we should be doing error handling? Previously we were using the following Sentry.captureexception in a try/catch in a hook call inside the component
  if (examplesError) {
    Sentry.captureException(examplesError)
    Services.HoneyComb.RecordError(examplesError)
  }

  return { examplesData, isExamplesPending, isExamplesFetching, examplesError }
}

const useExamplesQuery_Prefetch = (
  taskLabelLookup: Record<string, any>,
  isEnabled: boolean = true
) => {
  // get the query client
  const queryClient = useQueryClient()

  // creating the async function with a stable reference
  const getExamples = async () => await FetchExamples(taskLabelLookup)

  // The results of this query will be cached like a normal query
  // The difference is they will not refetch on each render, obey the stale time, etc. it is just a one time prefetch
  if (isEnabled) {
    prefetchWrapper(queryClient, {
      queryKey: [HarvQueryKeyPrefix.ExamplesQuery, taskLabelLookup],
      queryFn: getExamples,
    })
  }
  // the above call doesn't return anything
  return
}

// export
export { useExamplesQuery, useExamplesQuery_Prefetch }
